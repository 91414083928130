import { Injectable } from '@angular/core';
import { WritableFile } from '@root/workers/writableFile.worker';

@Injectable({
  providedIn: 'root'
})
export class WritableFileFactoryService {
  async create(path: string[]): Promise<WritableFile> {
    const constructor = WritableFile.construct();
    const file = await new constructor(path) as unknown as WritableFile;
    return file;
  }
}
