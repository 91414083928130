<h2 mat-dialog-title>{{title()}}</h2>
<mat-dialog-content>
    <p>{{message()}}</p>
    @if (initializing()) {
        <mat-progress-bar mode="indeterminate"/>
    }
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button (click)="init()" [disabled]="agree()">Yes</button>
</mat-dialog-actions>
