@if(trips.length == 0) {
<div class="no-data-container">
    <p>No data available</p>
</div>
} @else {

<mat-list>
    @for (trip of trips; track trip.name) {
        <mat-list-item>
            <div class="list-item-container">
                <p>{{trip.name}}</p>

                <button mat-icon-button 
                        [matMenuTriggerFor]="menu" 
                        [disabled]="!trip.progressTracker.completed()">
                    <mat-icon>more_vert</mat-icon>
                </button>

                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="openTripDetailsSheet(trip)">
                        <mat-icon>info</mat-icon>
                        <span>Info</span>
                    </button>
                    <button mat-menu-item (click)="uploadTrip(trip)">
                        <mat-icon>file_upload</mat-icon>
                        <span>Upload</span>
                    </button>
                    <button mat-menu-item (click)="downloadTrip(trip)">
                        <mat-icon>download</mat-icon>
                        <span>Download</span>
                    </button>
                    <button mat-menu-item (click)="deleteTrip(trip)">
                        <mat-icon color="warn">delete</mat-icon>
                        <span>Delete</span>
                    </button>
                </mat-menu>
            </div>
        </mat-list-item>
        <mat-progress-bar mode="determinate" [value]="trip.progressTracker.progress()" />
    }
</mat-list>
}