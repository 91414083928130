import { APP_INITIALIZER, ApplicationConfig, ErrorHandler, Provider, isDevMode } from '@angular/core';
import { Router, provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { provideServiceWorker } from '@angular/service-worker';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideHttpClient } from '@angular/common/http';

import * as Sentry from "@sentry/angular";

// GoMap
import { provideCSVWriterFactory } from '@services/csv-writer.service';
import { provideYoloModelFactory } from '@services/yolo-model-factory.service';

function provideSentry(enabled: boolean): Provider[] {
    if (!enabled) {
        return [];
    };
    return [
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({ showDialog: false })
        },
        {
            provide: Sentry.TraceService,
            deps: [Router]
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => { },
            deps: [Sentry.TraceService],
            multi: true
        }
    ]
}

export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(routes),
        provideServiceWorker('ngsw-worker.js', {
            enabled: !isDevMode(),
            registrationStrategy: 'registerWhenStable:30000'
        }),
        provideAnimations(),
        provideHttpClient(),
        provideCSVWriterFactory(),
        provideYoloModelFactory(),
        provideSentry(!isDevMode())
    ]
};
