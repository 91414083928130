<div class="container">
    <h3 mat-dialog-title>Max MBR Size</h3>

    <div class="row">
        <mat-slider min="32" max="200" step="1">
            <input matSliderThumb [(ngModel)]="settings.maxMbrSize">
        </mat-slider>
    
        <span class="label">{{settings.maxMbrSize}} px</span>
    </div>
</div>
