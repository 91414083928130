import { Injectable } from '@angular/core';

// GoMap
import { Detection, ObjectDetectionModel } from '@root/models/detection.model';
import * as CommonUtils from '@root/utilities/commonUtils';
import { SettingsService } from './settings.service';

export class Detector {
    constructor(
        public readonly model: ObjectDetectionModel,
        private readonly _settings: SettingsService,
    ) { }

    private _task: Promise<void> = Promise.resolve()
    get task(): Promise<void> {
        return this._task;
    }

    async detect(bitmap: ImageBitmap) {
        let detections = await this.model.detect(bitmap);
        detections = this._addTimestamp(detections);
        detections = this._addModelKey(detections);

        if (this._settings.includeMBR) {
            detections = this._addCrop(bitmap, detections)
        }

        return detections;
    }

    private async *_addTimestamp(detections: AsyncIterable<Detection>): AsyncGenerator<Detection> {
        const now = Date.now();
        for await (const detection of detections) {
            yield Object.assign(detection, { timestamp: now })
        }
    }

    private async *_addModelKey(detections: AsyncIterable<Detection>): AsyncGenerator<Detection> {
        const key = this.model.key;
        for await (const detection of detections) {
            yield Object.assign(detection, {
                modelId: key.id,
                modelVersion: key.version,
                modelSize: key.size
            });
        }
    }

    private async *_addCrop(bitmap: ImageBitmap, detections: AsyncIterable<Detection>): AsyncGenerator<Detection> {
        for await (const detection of detections) {
            const crop = await CommonUtils.crop(bitmap, detection, this._settings.maxMbrSize);
            const base64 = await CommonUtils.toBase64(crop);
            yield Object.assign(detection,
                { crop: base64.split(",")[1] }
            );
        }
    }

}

@Injectable({
    providedIn: 'root'
})
export class DetectorFactoryService {
    constructor(
        private readonly _settings: SettingsService
    ) { }

    create(model: ObjectDetectionModel): Detector {
        return new Detector(model, this._settings);
    }
}