import { Injectable, isDevMode } from '@angular/core';

// Typed JSON
import { TypedJSON } from 'typedjson';

// GoMap
import { Settings } from '@models/settings.model';
import { ApiService } from './api.service';
import { WritableFile } from '@root/workers/writableFile.worker';
import * as OPFS from '@root/utilities/opfsUtils';

const SERIALIZER = new TypedJSON(Settings);

@Injectable({ providedIn: "root" })
export class SettingsService extends Settings {
    constructor() { super() }

    async hasSettings(): Promise<boolean> {
        const dirHandle = await OPFS.getDirectoryHandle([])
        return OPFS.exists("settings.json", dirHandle)
    }

    async fetchRemoteSettings(): Promise<Settings> {
        const url = window.location.href;
        let filename: string;
        if (isDevMode()) {
            filename = "development.json";
        } else {
            filename = "production.json";
        }
        const response = await fetch(`${url}assets/settings/${filename}`)
        const settings = SERIALIZER.parse(await response.text())

        if (settings === undefined) {
            throw new Error("Could not parse settings file")
        }

        return settings;
    }

    async fetchLocalSettings(): Promise<Settings> {
        const txt = await OPFS.readText(["settings.json"]);
        const settings = SERIALIZER.parse(txt);

        if (settings === undefined) {
            throw new Error("Could not parse settings file");
        }
        return settings;
    }

    assignSettings(settings: Settings): SettingsService {
        return Object.assign(this, settings);
    }

    async getUserId(api: ApiService): Promise<string> {
        if (!this.userId) {
            this.userId = (await api.getUser()).id;
            await this.save();
        }

        return this.userId;
    }

    async save(): Promise<void> {
        const txt = SERIALIZER.stringify(this);
        await WritableFile.writeToFile(["settings.json"], txt);
        console.log("Settings file written")
    }
}
