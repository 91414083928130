import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Detection } from '@root/models/detection.model';
import * as OPFSUtils from '@root/utilities/opfsUtils';
import { WritableFile } from '@root/workers/writableFile.worker';

@Injectable({
    providedIn: 'root'
})
export class ObjectWhitelistCacheService {

    private _whitelist = new Set<string>();
    constructor(private readonly api: ApiService) { }

    async load(): Promise<void> {
        const txt = await OPFSUtils.readText(["whitelist.json"], { create: true });
        const whitelist: Array<string> = JSON.parse(txt ? txt : "[]");
        this._whitelist = new Set(whitelist);
    }

    async save(): Promise<void> {
        const json = JSON.stringify([...this._whitelist]);
        await WritableFile.writeToFile(["whitelist.json"], json);
    }

    async refresh(): Promise<void> {
        this._whitelist = await this.api.getObjectWhitelist();
        await this.save();
    }

    intersection(detections: Detection[]): Detection[] {
        return detections.filter(detection => this._whitelist.has(detection.classifier));
    }
}
