<mat-list>
    <h3>Details</h3>
    <mat-list-item>
        <span matListItemTitle>Name</span>
        <span matListItemLine>{{data.trip.name}}</span>
    </mat-list-item>
    <mat-list-item>
        <span matListItemTitle>Size</span>
        <span>{{info?.size | fileSize}}</span>
    </mat-list-item>
    <mat-list-item>
        <span matListItemTitle>Detections</span>
        <span>{{info?.detections}}</span>
    </mat-list-item>
    <mat-list-item>
        <span matListItemTitle>Positions</span>
        <span>{{info?.positions}}</span>
    </mat-list-item>
    <mat-list-item>
        <span matListItemTitle>Images</span>
        <span>{{info?.images}}</span>
    </mat-list-item>
</mat-list>