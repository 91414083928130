import { Directive, ElementRef, Input } from '@angular/core';

// GoMap
import { Detection, DetectionImage } from '@models/detection.model';

@Directive({
    selector: '[appDetectionCanvas]',
    standalone: true
})
export class DetectionCanvasDirective {
    @Input({ required: true }) set image(value: DetectionImage | undefined) {
        if (value === undefined) return;

        const scale = this._drawImage(value.image);
        this._drawDetections(value.detections, scale);
    }
    @Input() fontSize: number = 12;
    @Input() borderSize: number = 2;

    private _canvas: HTMLCanvasElement;
    private _ctx: CanvasRenderingContext2D;

    constructor(element: ElementRef<HTMLCanvasElement>) {
        this._canvas = element.nativeElement;
        this._ctx = this._getCanvasContext();
    }

    private _getCanvasContext(): CanvasRenderingContext2D {
        let ctx = this._canvas.getContext('2d');
        if (ctx == null) throw new Error("Could not get 2D canvas context");
        return ctx;
    }

    private _drawImage(bitmap: ImageBitmap) {
        const container = this._canvas.parentElement;

        if (container == null) {
            throw new Error("Could not get canvas parent");
        }
        if (this._ctx == null) {
            throw new Error("Could not get canvas context")
        }
        const containerWidth = container.offsetWidth;
        const containerHeight = container.offsetHeight;

        const widthRatio = containerWidth / bitmap.width;
        const heightRatio = containerHeight / bitmap.height;
        let scale = 1;

        if (widthRatio < heightRatio) {
            scale = widthRatio;
        } else {
            scale = heightRatio;
        }

        const width = bitmap.width * scale;
        const height = bitmap.height * scale;
        this._canvas.width = width;
        this._canvas.height = height;
        this._ctx.drawImage(bitmap, 0, 0, width, height);

        return scale;
    }

    private _drawBoundingBox(detection: Detection, scale: number) {
        this._ctx.beginPath();
        this._ctx.rect(detection.x * scale, detection.y * scale, detection.width * scale, detection.height * scale);
        this._ctx.lineWidth = this.borderSize;
        this._ctx.strokeStyle = 'green';
        this._ctx.stroke();
    }

    private _drawText(detection: Detection, scale: number) {
        const text = `${detection.classifier.toUpperCase()} ${detection.score.toFixed(2)}`;
        const x = detection.x * scale - this.borderSize / 2;
        let y = detection.y * scale - this.fontSize - this.borderSize * 2;
        this._ctx.font = `${this.fontSize}px Arial`;
        const width = this._ctx.measureText(text).width + this.borderSize * 2;
        const height = this.fontSize + this.borderSize * 2;

        if (y < 0) {
            y = detection.y * scale;
        }

        this._ctx.fillStyle = "green";
        this._ctx.fillRect(x, y, width, height);
        this._ctx.fillStyle = "white";
        this._ctx.fillText(
            text,
            detection.x * scale + this.borderSize,
            y + this.fontSize
        );
    }

    private _drawDetections(detections: Detection[], scale: number) {
        if (this._ctx == null) {
            throw new Error("Could not get canvas context")
        }

        for (const detection of detections) {
            this._drawBoundingBox(detection, scale);
            this._drawText(detection, scale);
        }
    }

}
