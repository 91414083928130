import { Injectable } from '@angular/core';
import { IAsyncDisposable } from '@root/models/common';
import { CameraAccessDeniedError, NoCameraAvailableError } from '@root/models/errors';

export class Camera implements IAsyncDisposable {
    private _track: MediaStreamTrack;
    constructor(
        public readonly stream: MediaStream,
        private readonly _video: HTMLVideoElement,
    ) {
        const track = stream.getVideoTracks().find(track => track.enabled && track.readyState == 'live')
        if (track == null) {
            throw new Error("No active video track found");
        }
        this._track = track;
    }
    private _disposed: boolean = false;
    get disposed(): boolean {
        return this._disposed;
    }

    takePhoto(): ImageBitmap {
        if (this.stream == null || this._track == null) {
            throw new Error("The camera service need to be successfully started in order to take a photo");
        }

        const settings = this._track.getSettings();
        if (settings.width == null || settings.height == null) {
            throw new Error("Both width and height must be known by the mediatrack");
        }
        const canvas = new OffscreenCanvas(settings.width, settings.height);
        const ctx = canvas.getContext('2d')

        if (ctx == null) {
            throw new Error("Could not get canvas context");
        }

        ctx.drawImage(this._video, 0, 0, canvas.width, canvas.height);
        return canvas.transferToImageBitmap();

    }

    async asyncDispose(): Promise<void> {
        this._video.pause();
        this._video.srcObject = null;

        if (this.stream != null) {
            this.stream.getTracks().forEach(track => track.stop())
        }
    }
}


@Injectable({
    providedIn: 'root'
})
export class CameraFactoryService {
    async create(idealWidth: number = 1280, idealHeight: number = 1280): Promise<Camera> {
        console.debug("Creating camera...");
        let stream: MediaStream | undefined;

        let status = { state: "prompt" } as PermissionStatus;
        if (!navigator.userAgent.search("Firefox")) {
            //@ts-ignore
            status = await navigator.permissions.query({ name: "camera" })
        }
        
        switch (status.state) {
            case "granted":
            case "prompt":
                try {
                    stream = await navigator.mediaDevices.getUserMedia({
                        audio: false,
                        video: {
                            facingMode: 'environment',
                            width: { ideal: idealWidth },
                            height: { ideal: idealHeight },
                            aspectRatio: { ideal: idealWidth / idealHeight },
                            //@ts-expect-error supported by chrome but not iOS
                            resizeMode: "none"
                        }
                    });
                } catch (error) {
                    throw new NoCameraAvailableError();
                }
                break;
            case "denied":
                throw new CameraAccessDeniedError();
        }

        const video = document.createElement('video');

        // Solves issue with Safari
        // https://stackoverflow.com/questions/72405566/cant-play-video-on-ios-in-safari-notallowederror-the-request-is-not-allowed-b
        video.playsInline = true;
        video.srcObject = stream;
        await video.play();
        return new Camera(stream, video);
    }
}
