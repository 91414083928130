<div class="container">
    <mat-card>
        <mat-card-header>
            <mat-card-title>Activated Model</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            @if(modelManager.activatedModel(); as key) {
                <table>
                    <tr>
                        <td>ID: </td>
                        <td>{{key.id}}</td>
                    </tr>
                    <tr>
                        <td>Size: </td>
                        <td>{{key.size}}</td>
                    </tr>
                    <tr>
                        <td>Version: </td>
                        <td>{{key.version}}</td>
                    </tr>
                </table>
            } @else {
                No model activated
            }
        </mat-card-content>

        @if (modelManager.activatedModel()) {
            <mat-card-actions align="end">
                <button mat-button
                        color="warn"
                        (click)="modelManager.deactivate()">
                    Deactivate
                </button>
            </mat-card-actions>
        }
    </mat-card>
    
    <div>
        <h1 class="hub-title">Hub</h1>
        @if(loading){
            <mat-spinner class="center" [diameter]="spinnerSize"></mat-spinner>
            }
        
            <mat-accordion>
                @for(model of ids(); track model.id) {
                <mat-expansion-panel (opened)="ensureInfo(model.id)">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{model.name}}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <ng-template matExpansionPanelContent>
                        @if(infoMap().get(model.id); as info) {
                            <app-model-panel [info]="info" [id]="model.id"></app-model-panel>
                        } @else {
                            <mat-spinner class="center" [diameter]="spinnerSize"></mat-spinner>
                        }
                    </ng-template>
                </mat-expansion-panel>
                }
        
            </mat-accordion>
    </div>
</div>