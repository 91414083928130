<div class="container">
    <h3>User Settings</h3>

    <div class="column row-gap">
        <div class="row">
            <span class="grow">ID: </span>
            <span>{{userId}}</span>
        </div>
        <button mat-raised-button color="primary" (click)="resetId()">Reset ID</button>
    </div>
</div>