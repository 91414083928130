import { Component, OnInit, signal } from '@angular/core';
import { CommonModule } from '@angular/common';

// Material
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';

// GoMap
import { ApiService } from '@root/services/api.service';
import { ModelPanelComponent } from '@root/components/model-panel/model-panel.component';
import { ModelInfo } from '@root/models/detection.model';
import { ModelId } from '@root/models/api.model';
import { ModelManagerService } from '@root/services/model-manager.service';
@Component({
    selector: 'app-model-zoo',
    standalone: true,
    imports: [
        CommonModule,
        ModelPanelComponent,
        MatExpansionModule,
        MatProgressSpinnerModule,
        MatCardModule,
        MatButtonModule
    ],
    templateUrl: './model-hub.page.html',
    styleUrls: ['./model-hub.page.scss']
})
export class ModelHubPage implements OnInit {
    spinnerSize: number = 40;
    loading: boolean = true;
    ids = signal<ModelId[]>([]);
    infoMap = signal<Map<string, ModelInfo>>(new Map());

    constructor(
        public readonly modelManager: ModelManagerService,
        private readonly _apiService: ApiService,
    ) { }

    async ngOnInit(): Promise<void> {
        const ids = await this._apiService.getModelIds();
        this.ids.set(ids);
        this.loading = false;
    }

    async ensureInfo(id: string) {
        let info = this.infoMap().get(id);
        if (!info) {
            info = await this._apiService.getModelInfo(id);
            this.infoMap.update(m => {
                m.set(id, info!);
                return m;
            });
        }
    }
}
