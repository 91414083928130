import { Injectable, Provider } from '@angular/core';

// TensorFlow
import * as tf from "@tensorflow/tfjs";
import { GraphModel } from "@tensorflow/tfjs";
import "@tensorflow/tfjs-backend-webgl";
import '@tensorflow/tfjs-backend-webgpu';

// GoMap
import { ModelKey } from '../models/detection.model';
import { YoloModel } from './yolo-model.service';
import { ObjectDetectionModelFactory } from '@models/detection.model';;
import * as OPFSUtils from '@root/utilities/opfsUtils';
import { ModelLoadError } from '@root/models/errors';

@Injectable({
    providedIn: 'root'
})
export class YoloModelFactoryService implements ObjectDetectionModelFactory {
    constructor() { }

    public async create(key: ModelKey) {
        await tf.ready();
        console.debug("Tensorflow ready")

        const path = `models/${key.id}/${key.version}/${key.size.toString()}/`
        let graphModel: GraphModel | undefined;
        
        try{
            graphModel = await tf.loadGraphModel(path + "model.json", {
                weightPathPrefix: path,
                fetchFunc: this._fetch
            });
        }
        catch (e){
            if (e instanceof Error) {
                throw new ModelLoadError(e);
            }
            throw e;
        }

        const classesFileHandle = await OPFSUtils.getFileHandle(["models", key.id, key.version, "classes.json"])
        const file = await classesFileHandle.getFile()
        const classes = <string[]>JSON.parse(await file.text());

        const model = new YoloModel(graphModel, classes, key);
        console.debug("Tensorflow model created");
        return model;
    }

    private async _fetch(input: RequestInfo | URL): Promise<Response> {
        let path: string[] = []
        if (input instanceof Request) {
            path = input.url.split("/");
        } else if (typeof input === "string" || input instanceof String) {
            path = input.split("/");
        } else if (input instanceof URL) {
            path = input.pathname.split("/");
        }
        
        const filename = path.pop();
        if (filename == null) {
            throw new Error("Invalid model or shard URL");
        }

        const modelDir = await OPFSUtils.getDirectoryHandle(path);
        const fileHandle = await modelDir.getFileHandle(filename);
        const file = await fileHandle.getFile();

        return new Response(file);
    }
}

export function provideYoloModelFactory(): Provider {
    return { provide: ObjectDetectionModelFactory, useClass: YoloModelFactoryService };
}