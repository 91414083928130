import { Injectable, Signal, signal } from '@angular/core';
import { IAsyncDisposable } from '@root/models/common';
import { LocationPermissionError } from '@root/models/errors';

export class PositionStream implements AsyncIterator<GeolocationPosition>, IAsyncDisposable {
    private _location = signal<GeolocationPosition | undefined>(undefined);
    private _watchId: number | undefined;
    private _promise!: Promise<GeolocationPosition | undefined>;
    private _prev_resolve!: (value: GeolocationPosition | undefined) => void;
    private _next_resolve!: (value: GeolocationPosition | undefined) => void;

    constructor() {
        this._promise = new Promise<GeolocationPosition | undefined>((resolve) => {
            this._next_resolve = resolve;
        });

        this._watchId = navigator.geolocation.watchPosition(pos => {
            this._location.set(pos);
            this._prev_resolve = this._next_resolve;
            this._promise = new Promise<GeolocationPosition | undefined>((resolve) => {
                this._next_resolve = resolve
            });
            this._prev_resolve(pos);
        },
            err => { throw err },
            { enableHighAccuracy: true }
        );
    }

    get location(): Signal<GeolocationPosition | undefined> {
        return this._location.asReadonly();
    }

    private _disposed: boolean = false;
    get disposed(): boolean { return this._disposed }

    async next(): Promise<IteratorResult<GeolocationPosition, undefined>> {
        const result = await this._promise;
        if (result === undefined) { 
            return { done: true, value: undefined } 
        }
        return { done: false, value: await result }
    }

    async asyncDispose(): Promise<void> {
        this._disposed = true;
        if (this._watchId === undefined) { return }
        navigator.geolocation.clearWatch(this._watchId);
        this._next_resolve(undefined)
    }

    [Symbol.asyncIterator](): AsyncIterator<GeolocationPosition> {
        return this;
    }
}


@Injectable({
    providedIn: 'root'
})
export class PositionStreamFactoryService {

    constructor() { }


    async create() {
        const result = await navigator.permissions.query({ name: "geolocation" });

        switch (result.state) {
            case "granted":
            case "prompt":
                return new PositionStream();
            case "denied":
                throw new LocationPermissionError();
        }
    }
}
