<div class="container">
    <router-outlet></router-outlet>
    <mat-toolbar>
        
        <a mat-icon-button routerLink="/map" routerLinkActive="mat-primary">
            <mat-icon>map</mat-icon>
        </a>

        @if(isMainPage) {
            @if(trip(); as trip) {
                @if(trip.state() === TripState.Started){
                    <button mat-mini-fab color="primary" (click)="stopTrip()">
                        <mat-icon>stop</mat-icon>
                    </button>
                }
                @else if (trip.progressTracker.completed()){
                    <mat-spinner mode="indeterminate"
                                [diameter]="40">
                    </mat-spinner>
                } @else {
                    <mat-spinner mode="determinate"
                                [value]="trip.progressTracker.progress()"
                                [diameter]="40">
                    </mat-spinner>
                }
            } @else {
                <button mat-mini-fab color="primary" (click)="startTrip()">
                    <mat-icon>play_arrow</mat-icon>
                </button>
            }
        } @else {
            <button mat-mini-fab routerLink="/" color="primary">
                <mat-icon>camera</mat-icon>
            </button>
        }

        <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
        </button>

        <mat-menu #menu="matMenu">
            <a mat-menu-item routerLink="/about">
                <mat-icon>info</mat-icon>
                <span>About</span>
            </a>
            <a mat-menu-item routerLink="/settings">
                <mat-icon>settings</mat-icon>
                <span>Settings</span>
            </a>

            <a mat-menu-item routerLink="/data">
                <mat-icon>description</mat-icon>
                <span>Data</span>
            </a>
    
            <a mat-menu-item routerLink="/hub">
                <mat-icon>hub</mat-icon>
                <span>Model Hub</span>
            </a>
        </mat-menu>
    </mat-toolbar>
</div>