import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

// GoMap
import { DetectionCanvasDirective } from '@directives/detection-canvas.directive';
import { TripManagerService, TripState } from '@root/services/trip-manager.service';


@Component({
    selector: 'app-main',
    standalone: true,
    imports: [
        CommonModule,
        DetectionCanvasDirective,
    ],
    templateUrl: './main.page.html',
    styleUrls: ['./main.page.scss'],
    host: { 'class': 'main-page-container' }
})
export class MainPage {
    TripState = TripState;

    constructor(
        public readonly tripManager: TripManagerService,
    ) {}
}
