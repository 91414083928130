import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

// Material
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
// GoMap
import { ModelManagerService } from '@root/services/model-manager.service';
import { ModelInfo, ModelKey } from '@root/models/detection.model';
import { ModelFlavorSortPipe } from '@root/pipes/model-flavor-sort.pipe';



@Component({
    selector: 'app-model-panel',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        MatSelectModule,
        MatFormFieldModule,
        MatButtonModule,
        MatTabsModule,
        MatExpansionModule,
        ModelFlavorSortPipe
    ],
    templateUrl: './model-panel.component.html',
    styleUrls: ['./model-panel.component.scss']
})
export class ModelPanelComponent {
    @Input({ required: true }) id!: string;
    @Input({ required: true }) info!: ModelInfo;

    tabIndex: number = 0;
    isInstalled: boolean = false;
    isActivated: boolean = false;
    installing: boolean = false;
    uninstalling: boolean = false;

    private _selectedVersion?: string;
    set selectedVersion(val: string | undefined) {
        this._selectedVersion = val;
        if (val == null) {
            this.isInstalled = false;
            return;
        }

        const key = this._getKey();
        this.isActivated = this._modelManager.activatedModel()?.equals(key) ?? false;
        this.isInstalled = this._modelManager.isInstalled(key);
    }
    get selectedVersion(): string | undefined {
        return this._selectedVersion;
    }

    get size(): string | undefined {
        return this.info.flavors[this.tabIndex].size;
    }

    constructor(
        private readonly _modelManager: ModelManagerService
    ) { }


    onTabChange(): void {
        this.reset();
    }

    reset() {
        this.selectedVersion = undefined;
    }

    async install() {
        const key = this._getKey();
        this.installing = true;
        await this._modelManager.install(key)
        this.installing = false;
        this.isInstalled = true;
    }

    async uninstall() {
        const key = this._getKey();
        this.uninstalling = true;
        await this._modelManager.uninstall(key)
        this.uninstalling = false;
        this.isInstalled = false;
    }

    async activate() {
        const key = this._getKey();
        await this._modelManager.activate(key)
        this.isActivated = true;
    }

    async deactivate() {
        await this._modelManager.deactivate()
        this.isActivated = false;
    }

    sortVersionStrings(arr: string[]): string[] {
        return [...arr].sort((a, b) => b.localeCompare(a));
    }

    private _getKey(): ModelKey {
        if (this.selectedVersion == null || this.size == null) {
            throw new Error("ObjectDetectionModelKey cannot be created without version being set")
        }

        return new ModelKey(
            this.id,
            this.selectedVersion,
            this.size
        )
    }
}
