/// <reference lib="webworker" />
import { IAsyncDisposable } from '@root/models/common';
import * as OPFSUtils from '@root/utilities/opfsUtils'
import * as Comlink from 'comlink/dist/esm/comlink'

export class WritableFile implements IAsyncDisposable {
    private _accessHandle?: FileSystemSyncAccessHandle;
    private _cursorLocation: number = 0;

    constructor(public readonly path: ReadonlyArray<string>) { }
    
    private _disposed: boolean = false;
    get disposed(): boolean {
        return this._disposed;
    }

    get size(): number {
        if (this._accessHandle == null) {
            throw new Error("File handle not opned")
        }
        return this._accessHandle.getSize();
    }

    async asyncDispose(): Promise<void> {
        this._disposed = true;
        await this.close();
    }

    static async writeToFile(
        path: ReadonlyArray<string>, data: string | ArrayBuffer | Blob,
        create: boolean = true,
        append: boolean = false
    ) {
        const writableFile = WritableFile.construct()
        const file = await new writableFile(path);
        try {
            await file.open(create, append);
            await file.write(data)
        } finally {
            await file.close();
        }
    }

    async open(create: boolean = true, append: boolean = false) {
        if (this._accessHandle != null) {
            throw new Error("File handle already opned")
        }
        const fileHandle = await OPFSUtils.getFileHandle(this.path, { create })
        this._accessHandle = await fileHandle.createSyncAccessHandle();

        if (append) {
            this._cursorLocation = this._accessHandle.getSize(); 
        } else {
            this._accessHandle.truncate(0);
        }
    }

    async write(data: string | Blob | ArrayBuffer) {
        if (this._accessHandle == null) {
            throw new Error("File handle not opned")
        }

        if (typeof data === 'string' || data instanceof String) {
            const encoder = new TextEncoder();
            data = encoder.encode(<string>data);
        } else if (data instanceof Blob) {
            data = await data.arrayBuffer()
        }

        this._cursorLocation += this._accessHandle.write(data, { at: this._cursorLocation })
    }

    async flush() {
        if (this._accessHandle == null) {
            throw new Error("File handle not opned")
        }
        this._accessHandle.flush();
    }

    async close() {
        this._accessHandle?.flush();
        this._accessHandle?.close();
        self.close();
    }

    static construct() {
        const worker = new Worker(new URL('src/app/workers/writableFile.worker', import.meta.url));
        return Comlink.wrap<typeof WritableFile>(worker);
    }
}

Comlink.expose(WritableFile);