import { isDevMode } from "@angular/core";
import { ModelKey } from "./detection.model";
import { jsonObject, jsonMember } from 'typedjson';

export interface ImageSize {
    width: number;
    height: number;
}

export enum PhotoSaveMode {
    Photo = "Photo",
    ObjectPhoto = "ObjectPhoto",
    WhitelistObjectPhoto = "WhitelistObjectPhoto"
}

export enum PhotoCaptureMode {
    TimeInterval = "TimeInterval",
    PointBasedDistanceInterval = "PointBasedDistanceInterval",
    SpeedBasedDistanceInterval = "SpeedBasedDistanceInterval"
}

@jsonObject
export class WebURL {
    @jsonMember scheme: string = isDevMode() ? "http" : "https";
    @jsonMember host: string = isDevMode() ? "localhost" : "edge.gomap.duckdns.org";
    @jsonMember({ preserveNull: true }) port?: number = isDevMode() ? 8000 : undefined;
    @jsonMember({ preserveNull: true }) path?: string = isDevMode() ? undefined : "api";

    toString(): string {
        let url = `${this.scheme}://${this.host}`
        if (this.port) {
            url += `:${this.port}`
        }
        if (this.path) {
            url += `/${this.path}`
        }
        return url;
    }
}
@jsonObject
export class CaptureSettings {
    @jsonMember captureMode: PhotoCaptureMode = PhotoCaptureMode.SpeedBasedDistanceInterval;
    @jsonMember idealSize: ImageSize = { width: 1920, height: 1440 };
    @jsonMember timeInterval: number = 1000; // In milliseconds
    @jsonMember distanceInterval: number = 10; // In meters
    @jsonMember speedThreshold: number = 5 / 3.6 // In meters per second;
    @jsonMember save: boolean = false;
    @jsonMember saveMode: PhotoSaveMode = PhotoSaveMode.WhitelistObjectPhoto;
    @jsonMember history: number = 0;
}

@jsonObject
export class Settings {
    dataPath: string[] = ["data"];
    @jsonMember userId?: string;
    @jsonMember autoUpload: boolean = true;
    @jsonMember deleteAfterUpload: boolean = true;
    @jsonMember includeMBR: boolean = true;
    @jsonMember maxMbrSize: number = 200; // In pixels
    @jsonMember apiSettings: WebURL = new WebURL();
    @jsonMember activeModel?: ModelKey;
    @jsonMember captureSettings: CaptureSettings = new CaptureSettings();
}
