import 'reflect-metadata';
import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import * as Sentry from "@sentry/angular";

Sentry.init({
  dsn: "https://3eee652395945fe68d7d145c593fe741@o4506361991462912.ingest.sentry.io/4506361996050432",
  integrations: [],
});

bootstrapApplication(AppComponent, appConfig)
  .catch((err) => console.error(err));
