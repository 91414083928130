import { WritableFile } from "@root/workers/writableFile.worker";

export abstract class Writer {
    public abstract write(obj: object): Promise<void>;
    public abstract writeAll(objs: Iterable<object>): Promise<void>;
}

export abstract class WriterFactory {
    public abstract create(writableFile: WritableFile): Writer;
}
