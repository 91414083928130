import { Injectable, Signal, signal } from '@angular/core';

// GoMap
import { SettingsService } from './settings.service';
import { ApiService } from './api.service';
import * as OPFSUtils from '@root/utilities/opfsUtils';
import * as CommonUtils from '@root/utilities/commonUtils';
import { Detection, DetectionImage, ObjectDetectionModel } from '@root/models/detection.model';
import { Writer, WriterFactory } from '@root/models/writer.model';
import { PhotoSaver, PhotoSaverFactoryService } from './photo-saver-factory.service';
import { InvalidStateError, NoActiveModelError } from '@root/models/errors';
import { HttpErrorResponse } from '@angular/common/http';
import { Detector, DetectorFactoryService } from './detector-factory.service';
import { ModelManagerService } from './model-manager.service';
import { Camera, CameraFactoryService } from './camera-factory.service';
import { WritableFileFactoryService } from './writable-file-factory.service';
import { ProgressTracker } from './progress-tracker-factory.service';
import { SequenceFactoryService, TripSequencer } from './sequence-factory.service';
import { PositionStream, PositionStreamFactoryService } from './position-stream-factory.service';
import { IAsyncDisposable } from '@root/models/common';
import { BlobWriter, ZipWriter } from '@zip.js/zip.js';

export interface TripInfo {
    size: number
    detections: number
    positions: number
    images: number
}

export class Trip {
    public progressTracker = new ProgressTracker(0);
    protected readonly directory = this.settings.dataPath.concat(this.name);

    constructor(
        public readonly name: string,
        protected readonly settings: SettingsService,
        private readonly _api: ApiService
    ) { }

    private _info: Promise<TripInfo> | undefined;
    get info(): Promise<TripInfo> {
        if (this._info === undefined) {
            this._info = this._getInfo();
        }
        return this._info;
    }


    async *getTripFiles(): AsyncIterable<File> {
        const tripDirHandle = await OPFSUtils.getDirectoryHandle(this.directory);

        for await (const [filename, fileHandle] of tripDirHandle) {
            if (fileHandle.kind !== 'file') {
                this.progressTracker.tick();
                continue;
            }
            yield await fileHandle.getFile();
        }
    }


    async upload() {
        const tripDirHandle = await OPFSUtils.getDirectoryHandle(this.directory);
        const keys = await CommonUtils.asyncIterableToArray(tripDirHandle.keys());
        this.progressTracker = new ProgressTracker(keys.length);

        for await (const [filename, fileHandle] of tripDirHandle) {
            if (fileHandle.kind !== 'file') {
                this.progressTracker.tick();
                continue;
            }

            const file = await fileHandle.getFile();
            if (file.size > 0) {
                try {
                    await this._api.upload(file, tripDirHandle.name);
                    this.progressTracker.tick();
                } catch (e) {
                    if (e instanceof HttpErrorResponse) {
                        switch (e.status) {
                            case 409:
                                this.progressTracker.tick();
                                continue;
                            default:
                                throw e;
                        }
                    }
                    throw e;
                }

                console.debug(
                    `Uploaded ${this.settings.dataPath.concat(tripDirHandle.name, filename).join("/")}`
                );
            }
        }

        if (this.settings.deleteAfterUpload) {
            this.remove();
        }
    }

    /**
     * Asynchronously archives trip files into a zip format.
     *
     * @return {Promise<Blob>} A Promise resolving to a Blob representing the archived trip files.
     */
    async archive(): Promise<Blob> {
        const files = await CommonUtils.asyncIterableToArray(this.getTripFiles())
        this.progressTracker = new ProgressTracker(files.length);
        const zipFileWriter = new BlobWriter("application/zip");
        const zipWriter = new ZipWriter(zipFileWriter);

        const proimises = [];
        for (const file of files) {
            this.progressTracker.tick();
            proimises.push(zipWriter.add(file.name, file.stream()))
        }
        await Promise.all(proimises)
        return await zipWriter.close()
    }

    async remove() {
        const dirHandle = await OPFSUtils.getDirectoryHandle(this.settings.dataPath);
        const exists = await OPFSUtils.exists(this.name, dirHandle);
        if (!exists) { return; }
        await dirHandle.removeEntry(this.name, { recursive: true });
    }

    private async _getInfo(): Promise<TripInfo> {
        const tripDirHandle = await OPFSUtils.getDirectoryHandle(
            this.settings.dataPath.concat(this.name)
        );

        let size = 0;
        let detections = 0;
        let positions = 0;
        let images = 0;

        for await (const [, fileHandle] of tripDirHandle) {
            if (fileHandle.kind !== 'file') {
                continue;
            }
            const file = await fileHandle.getFile();
            const extension = file.name.split(".").pop()?.toLowerCase();
            if (!extension) {
                continue;
            }

            if (file.name === "detections.csv") {
                const txt = await file.text();
                detections = txt.split("\n").length - 1;
                size += file.size;
            } else if (file.name === "trajectory.csv") {
                const txt = await file.text();
                positions = txt.split("\n").length - 1;
                size += file.size;
            } else if (["jpg", "jpeg", "png"].includes(extension)) {
                images++;
                size += file.size;
            }
        }

        return { size, detections, positions, images };
    }
}

export enum TripState {
    Created,
    CreatingModel,
    ActivatingCamera,
    Started,
    Stopping,
    Stopped
}

export class ActiveTrip extends Trip implements IAsyncDisposable {
    private _runner = Promise.resolve()
    private _disposables: IAsyncDisposable[] = [];
    private _sequencer: TripSequencer | undefined;

    constructor(
        name: string,
        settings: SettingsService,
        api: ApiService,
        private readonly _detectorFactory: DetectorFactoryService,
        private readonly _cameraFactory: CameraFactoryService,
        private readonly _writerFactory: WriterFactory,
        private readonly _writableFileFactory: WritableFileFactoryService,
        private readonly _modelManager: ModelManagerService,
        private readonly _photoSaverFactory: PhotoSaverFactoryService,
        private readonly _sequencerFactory: SequenceFactoryService,
        private readonly _positionStreamFactory: PositionStreamFactoryService
    ) {
        super(name, settings, api)
    }

    disposed: boolean = false;

    private _state = signal<TripState>(TripState.Created)
    get state(): Signal<TripState> {
        return this._state.asReadonly();
    }

    private _detectionImage = signal<DetectionImage | undefined>(undefined);
    get detectionImage(): Signal<DetectionImage | undefined> {
        return this._detectionImage.asReadonly();
    }

    private async _createWriter(path: string[]): Promise<Writer> {
        const file = await this._writableFileFactory.create(path);
        this._disposables.push(file);
        await file.open(true, true);
        return this._writerFactory.create(file);
    }

    async start(): Promise<void> {
        const detectionWriter = await this._createWriter(this.directory.concat("detections.csv"));
        const trajectoryWriter = await this._createWriter(this.directory.concat("trajectory.csv"));

        // Do not dispose the model. 
        // The ModelManagerService takes care of the model lifecycle.
        this._state.set(TripState.CreatingModel);
        const model = await this._getModel();

        this._state.set(TripState.ActivatingCamera);
        const camera = await this._cameraFactory.create(
            this.settings.captureSettings.idealSize.width,
            this.settings.captureSettings.idealSize.height
        );
        const detector = this._detectorFactory.create(model);
        this._sequencer = await this._sequencerFactory.create();
        const photoSaver = this._photoSaverFactory.create(this.directory);
        const positionStream = await this._positionStreamFactory.create();
        this._disposables.push(camera, photoSaver, positionStream)

        this._runner = this._run(
            this._sequencer,
            camera,
            detector,
            photoSaver,
            positionStream,
            detectionWriter,
            trajectoryWriter
        );
        this._state.set(TripState.Started);
    }

    async stop(): Promise<void> {
        const state = this.state();
        if (state === TripState.Stopped || state === TripState.Stopping) {
            return;
        }
        this._state.set(TripState.Stopping)

        await this.asyncDispose();

        const info = await this.info;
        if (info.size === 0) {
            await this.remove();
        }
        else if (this.settings.autoUpload) {
            try {
                await this.upload();
            } catch (e) {
                this._state.set(TripState.Stopped)
                throw e
            }
        }
        this._state.set(TripState.Stopped)
    }

    async asyncDispose(): Promise<void> {
        await this._sequencer?.asyncDispose();
        await this._runner;
        const disposed = this._disposables.map(d => d.asyncDispose())
        await Promise.all(disposed);
        this.disposed = true;
    }

    private async _getModel(): Promise<ObjectDetectionModel> {
        const key = this.settings.activeModel;
        if (key == null) {
            throw new NoActiveModelError();
        }
        const model = await this._modelManager.getModel(key);
        await model.warmup();
        return model;
    }

    private async *_addSequenceId(sequenceId: number, detections: AsyncIterable<Detection>): AsyncGenerator<Detection> {
        for await (const detection of detections) {
            yield Object.assign(detection, { sequenceId: sequenceId })
        }
    }

    private async _run(
        sequencer: TripSequencer,
        camera: Camera,
        detector: Detector,
        photoSaver: PhotoSaver,
        positionStream: PositionStream,
        detectionWriter: Writer,
        trajectoryWriter: Writer
    ): Promise<void> {
        try {
            for await (const i of sequencer) {
                const bitmap = camera.takePhoto();
                let detections = await detector.detect(bitmap);
                detections = this._addSequenceId(i, detections);

                const detectionImage = {
                    id: i,
                    image: bitmap,
                    detections: await CommonUtils.asyncIterableToArray(detections)
                }
                this._detectionImage.set(detectionImage);
                await Promise.all([
                    detectionWriter.writeAll(detectionImage.detections),
                    trajectoryWriter.write({
                        sequenceId: i,
                        timestamp: positionStream.location()?.timestamp,
                        latitude: positionStream.location()?.coords.latitude,
                        longitude: positionStream.location()?.coords.longitude,
                        accuracy: positionStream.location()?.coords.accuracy,
                        altitude: positionStream.location()?.coords.altitude,
                        altitudeAccuracy: positionStream.location()?.coords.altitudeAccuracy,
                        heading: positionStream.location()?.coords.heading,
                        speed: positionStream.location()?.coords.speed
                    }),
                    photoSaver.add(detectionImage)
                ]);
            }
        } finally {
            if (!sequencer.disposed) {
                console.log(sequencer)
                await sequencer.asyncDispose();
            }
        }
    }
}

@Injectable({
    providedIn: 'root'
})
export class TripManagerService {
    constructor(
        private readonly _settings: SettingsService,
        private readonly _api: ApiService,
        private readonly _writableFileFactory: WritableFileFactoryService,
        private readonly _writerFactory: WriterFactory,
        private readonly _photoSaverFactory: PhotoSaverFactoryService,
        private readonly _detectorFactory: DetectorFactoryService,
        private readonly _modelManager: ModelManagerService,
        private readonly _cameraFactory: CameraFactoryService,
        private readonly _sequenceFactory: SequenceFactoryService,
        private readonly _positionStreamFactory: PositionStreamFactoryService,
    ) { }

    public activeTrip = signal<ActiveTrip | undefined>(undefined);

    // async uploadAllTrips() {
    //     const dataDirHandle = await OPFSUtils.getDirectoryHandle(
    //         this._settings.dataPath,
    //         { create: true }
    //     );

    //     for await (const [tripName, tripDirHandle] of dataDirHandle) {
    //         if (tripDirHandle.kind !== 'directory') {
    //             continue;
    //         }

    //         const trip = new Trip(tripName, this._settings, this._api);
    //         await trip.upload();
    //     }
    // }

    async *getTrips(): AsyncIterable<Trip> {
        const dataDirHandle = await OPFSUtils.getDirectoryHandle(
            this._settings.dataPath,
            { create: true }
        );

        for await (const [tripDirName, tripDirHandle] of dataDirHandle) {
            if (tripDirHandle.kind !== 'directory') {
                continue;
            }

            yield new Trip(tripDirName, this._settings, this._api);
        }
    }

    async createTrip(): Promise<ActiveTrip> {
        if (this.activeTrip() !== undefined && this.activeTrip()?.state() !== TripState.Stopped) {
            throw new InvalidStateError("A trip is already active");
        }
        const tripName = CommonUtils.getCurrentDateTimeFormatted();

        const trip = new ActiveTrip(
            tripName,
            this._settings,
            this._api,
            this._detectorFactory,
            this._cameraFactory,
            this._writerFactory,
            this._writableFileFactory,
            this._modelManager,
            this._photoSaverFactory,
            this._sequenceFactory,
            this._positionStreamFactory
        );
        this.activeTrip.set(trip);
        return trip;
    }
}
