import { Pipe, PipeTransform } from '@angular/core';
import { Flavor } from '@root/models/detection.model';

@Pipe({
    name: 'modelFlavorSort',
    standalone: true
})
export class ModelFlavorSortPipe implements PipeTransform {

    private _sort(a: Flavor, b: Flavor): number {
        const aWidth = Number(a.size.split("x")[0])
        const bWidth = Number(b.size.split("x")[0])
        return aWidth - bWidth;
    }

    transform(value: Flavor[], ...args: unknown[]): Flavor[] {
        return value.sort(this._sort);
    }

}
