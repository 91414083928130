export async function getDirectoryHandle(
    path: string[],
    options: FileSystemGetDirectoryOptions | undefined = undefined
): Promise<FileSystemDirectoryHandle> {
    let root = await navigator.storage.getDirectory()
    for (const dir of path) {
        root = await root.getDirectoryHandle(dir, options)
    }
    return root;
}

export async function getFileHandle(
    path: ReadonlyArray<string>,
    options: FileSystemGetFileOptions | undefined = undefined
): Promise<FileSystemFileHandle> {
    const dir = path.slice(0, path.length - 1)
    const filename = path[path.length - 1]
    const dirHandle = await getDirectoryHandle(dir, options)
    return await dirHandle.getFileHandle(filename, options)
}

export async function exists(name: string, dir: FileSystemDirectoryHandle): Promise<boolean> {
    for await (const key of dir.keys()) {
        if (key === name) { return true; }
    }
    return false;
}

export async function isEmptyDirectory(handle: FileSystemDirectoryHandle): Promise<boolean> {
    const next = await handle.entries().next();
    return next.done ?? false;
}

export async function readText(
    path: ReadonlyArray<string>,
    options: FileSystemGetFileOptions | undefined = undefined
): Promise<string> {
    const handle = await getFileHandle(path, options);
    const file = await handle.getFile();
    return await file.text();
}