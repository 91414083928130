import { Injectable, signal, effect } from '@angular/core';

// Tensorflow
import { ModelJSON } from '@tensorflow/tfjs-core/dist/io/types'

// GoMap
import { SettingsService } from './settings.service';
import { ApiService } from './api.service';
import {
    DetectionModel,
    ModelInfo,
    ModelKey,
    ModelSize,
    ModelVersion,
    ObjectDetectionModel,
    ObjectDetectionModelFactory
} from '@root/models/detection.model';
import * as OPFSUtils from '@root/utilities/opfsUtils';
import { WritableFile } from '@root/workers/writableFile.worker';

interface ModelCache {
    model: ObjectDetectionModel;
    key: ModelKey;
}

@Injectable({
    providedIn: 'root'
})
export class ModelManagerService {
    activatedModel = signal<ModelKey | undefined>(undefined)
    installedModels = signal<DetectionModel[]>([])
    private _modelIndex: Set<string> = new Set();
    private _modelCache: ModelCache | null = null;

    constructor(
        private readonly _apiService: ApiService,
        private readonly _settings: SettingsService,
        private readonly _modelFactory: ObjectDetectionModelFactory
    ) {
        this.activatedModel.set(this._settings.activeModel);
        this._getInstalledModels()
            .then(models => this.installedModels.set(models))
            .catch(reason => { throw reason });

        // Create Model index
        effect(() => {
            const installedModels = this.installedModels();
            this._modelIndex.clear();
            installedModels.forEach(model => {
                model.versions.forEach(version => {
                    version.sizes.forEach(size => {
                        this._modelIndex.add(`${model.id}/${version.name}/${size.toString()}`)
                    });
                });
            });
        })
    }

    async getModel(key: ModelKey): Promise<ObjectDetectionModel> {
        if (!this.isInstalled(key)) {
            throw new Error(`No such model installed '${key}'`);
        }
        if (this._modelCache == null) {
            this._modelCache = {
                model: await this._modelFactory.create(key),
                key: key
            }
        }
        else if (!this._modelCache.key.equals(key)) {
            this._modelCache.model.dispose();
            this._modelCache.model = await this._modelFactory.create(key);
            this._modelCache.key = key;
        }
        return this._modelCache.model;
    }

    isInstalled(key: ModelKey): boolean {
        return this._modelIndex.has(key.toString());
    }

    async install(key: ModelKey) {
        const path = ["models", key.id];
        const info = await this._apiService.getModelInfo(key.id);
        await WritableFile.writeToFile(
            path.concat(["info.json"]),
            JSON.stringify(info)
        )

        path.push(key.version);
        const classes = await this._apiService.getModelClasses(key);
        await WritableFile.writeToFile(
            path.concat(["classes.json"]),
            JSON.stringify(classes)
        )

        const jsonModel = await this._apiService.getModel(key);
        path.push(key.size);
        await WritableFile.writeToFile(
            path.concat(["model.json"]),
            JSON.stringify(jsonModel)
        );

        await this._fetchShards(jsonModel, key, path);
        this.installedModels.set(await this._getInstalledModels());
    }

    async uninstall(key: ModelKey) {
        const modelDir = await OPFSUtils.getDirectoryHandle(
            ["models", key.id, key.version]
        );
        await modelDir.removeEntry(key.size, { recursive: true });
        this.installedModels.set(await this._getInstalledModels());
        if (this.activatedModel()?.equals(key)) {
            await this.deactivate();
        }
    }

    async activate(key: ModelKey) {
        this._settings.activeModel = key;
        await this._settings.save();
        this.activatedModel.set(key);
    }

    async deactivate() {
        this._settings.activeModel = undefined;
        await this._settings.save();
        this.activatedModel.set(undefined);
    }

    //#region OPFS model handling
    private async _getModelInfo(dir: FileSystemDirectoryHandle): Promise<ModelInfo | null> {
        try {
            const infoFileHandle = await dir.getFileHandle("info.json")
            const file = await infoFileHandle.getFile()
            return <ModelInfo>JSON.parse(await file.text());
        } catch {
            return null;
        }
    }

    private async _getClasses(dir: FileSystemDirectoryHandle): Promise<string[] | null> {
        try {
            const classesFileHandle = await dir.getFileHandle("classes.json")
            const file = await classesFileHandle.getFile()
            return <string[]>JSON.parse(await file.text());
        } catch {
            return null;
        }

    }

    private async _getSizes(dir: FileSystemDirectoryHandle): Promise<ModelSize[]> {
        const sizes: ModelSize[] = [];
        for await (const [size, sizeHandle] of dir.entries()) {
            if (sizeHandle.kind === 'file') { continue }
            const sizeDir = await dir.getDirectoryHandle(size);
            if (await this._isValidModel(sizeDir)) {
                sizes.push(ModelSize.fromString(size));
            } else {
                console.warn("Corrupt model detected, removing directory");
                await dir.removeEntry(size, { recursive: true })
                continue;
            }
        }

        return sizes;
    }

    private async _getVersions(dir: FileSystemDirectoryHandle): Promise<ModelVersion[]> {
        const versions: ModelVersion[] = [];
        for await (const [version, versionHandle] of dir.entries()) {
            if (versionHandle.kind === 'file') { continue }
            const versionDir = await dir.getDirectoryHandle(version);
            const classes = await this._getClasses(versionDir)

            if (classes == null) {
                console.warn(`Could not successfully find or read 'classes.json'`);
                continue;
            }

            const sizes: ModelSize[] = await this._getSizes(versionDir);
            if (sizes.length === 0) {
                continue;
            }
            versions.push(
                new ModelVersion(version, sizes, classes)
            )
        }
        return versions;
    }

    private async _getModels(dir: FileSystemDirectoryHandle): Promise<DetectionModel[]> {
        const models: DetectionModel[] = []
        for await (const [modelId, handle] of dir.entries()) {
            if (handle.kind === 'file') { continue }
            const modelDir = await dir.getDirectoryHandle(modelId);

            const info = await this._getModelInfo(modelDir)
            if (info == null) {
                console.warn(`Could not successfully find or read 'info.json'`);
                continue;
            }

            const versions = await this._getVersions(modelDir)
            if (versions.length === 0) {
                continue;
            }
            models.push({
                id: modelId,
                info,
                versions
            });
        }

        return models;
    }

    private async _isValidModel(modelDirHandle: FileSystemDirectoryHandle) {
        const modelFilename = "model.json"
        if (!await OPFSUtils.exists(modelFilename, modelDirHandle)) {
            console.warn(`Model missing: ${modelFilename}`)
            return false;
        }

        const fileHandle = await modelDirHandle.getFileHandle(modelFilename);
        const file = await fileHandle.getFile();
        const model = <ModelJSON>JSON.parse(await file.text())

        let isValid = true;
        for (const group of model.weightsManifest) {
            for (const path of group.paths) {
                if (!await OPFSUtils.exists(path, modelDirHandle)) {
                    console.warn(`Shard missing: ${path}`)
                    isValid = false;

                }
            }
        }
        return isValid;
    }

    private async _getInstalledModels(): Promise<DetectionModel[]> {
        const modelRoot = await OPFSUtils.getDirectoryHandle(["models"], { create: true });
        return await this._getModels(modelRoot);
    }
    //#endregion


    private async _fetchShards(
        model: ModelJSON,
        key: ModelKey,
        path: string[],
        chunkSize: number = 4
    ) {
        for (const group of model.weightsManifest) {
            for (let i = 0; i < group.paths.length; i += chunkSize) {
                const chunk = group.paths.slice(i, i + chunkSize);
                const tasks: Promise<void>[] = [];
                for (const shard of chunk) {
                    const task = this._fetchShard(key, shard, path)
                    tasks.push(task);
                }
                await Promise.all(tasks);
            }
        }
    }

    private async _fetchShard(key: ModelKey, shard: string, path: string[]) {
        const shardObj = await this._apiService.getModelShard(key, shard);
        await WritableFile.writeToFile(
            path.concat([shard]),
            shardObj
        )
    }
}
