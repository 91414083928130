export const EARTH_RADIUS: number = 6371e3;

/**
 * Converts a given angle from degrees to radians.
 *
 * @param {number} angle - The angle in degrees to be converted.
 * @return {number} The angle in radians.
 */
export function toRadians(angle: number): number {
    return angle * (Math.PI / 180);
}

/**
 * Calculates the distance between two geographical coordinates.
 *
 * @param {number} lat1 - The latitude of the first coordinate.
 * @param {number} lng1 - The longitude of the first coordinate.
 * @param {number} lat2 - The latitude of the second coordinate.
 * @param {number} lng2 - The longitude of the second coordinate.
 * @return {number} The distance between the two coordinates in meters.
 */
export function distance(lat1: number, lng1: number, lat2: number, lng2: number): number {
    lat1 = toRadians(lat1);
    lng1 = toRadians(lng1);
    lat2 = toRadians(lat2);
    lng2 = toRadians(lng2);

    const deltaLng = lng2 - lng1
    return Math.acos(Math.sin(lat1) * Math.sin(lat2) + Math.cos(lat1) * Math.cos(lat2) * Math.cos(deltaLng)) * EARTH_RADIUS;
}

/**
 * Calculates the speed between two coordinates based on the distance and time difference.
 *
 * @param {number} lat1 - The latitude of the first coordinate.
 * @param {number} lng1 - The longitude of the first coordinate.
 * @param {number} time1 - The time of the first coordinate in Unix time (milliseconds).
 * @param {number} lat2 - The latitude of the second coordinate.
 * @param {number} lng2 - The longitude of the second coordinate.
 * @param {number} time2 - The time of the second coordinate in Unix time (milliseconds).
 * @return {number} The speed between the two coordinates in meters per second.
 */
export function speedFromCoords(lat1: number, lng1: number, time1: number, lat2: number, lng2: number, time2: number): number {
    return speedFromDistance(distance(lat1, lng1, lat2, lng2), time2 - time1)
}

/**
 * Calculates the speed in meters per second based on the distance and time.
 *
 * @param {number} distance - The distance traveled in meters.
 * @param {number} time - The time taken to travel the distance in milliseconds.
 * @return {number} The speed in meters per second.
 */
export function speedFromDistance(distance: number, time: number): number {
    return distance / (time / 1000)
}