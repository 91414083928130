import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import packageJson from '../../../../package.json';

@Component({
  selector: 'app-about',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './about.page.html',
  styleUrls: ['./about.page.scss'],
  host: { 'class': 'about-page-container' }
})
export class AboutPage {
    get version() {
        return packageJson.version;
    }
}
