import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';

// Material
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import {
    MAT_BOTTOM_SHEET_DATA,
    MatBottomSheet,
    MatBottomSheetModule,
    MatBottomSheetRef
} from '@angular/material/bottom-sheet';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';

// Misc
import { firstValueFrom } from 'rxjs';

// GoMap
import { SettingsService } from '@root/services/settings.service';
import { FileSizePipe } from '@root/pipes/file-size.pipe';
import { TripManagerService, TripInfo, Trip } from '@root/services/trip-manager.service';
import * as CommonUtils from '@root/utilities/commonUtils';

@Component({
    selector: 'app-data',
    standalone: true,
    imports: [
        CommonModule,
        MatListModule,
        MatIconModule,
        MatButtonModule,
        MatBottomSheetModule,
        MatSnackBarModule,
        MatMenuModule,
        MatProgressBarModule
    ],
    templateUrl: './data.page.html',
    styleUrls: ['./data.page.scss'],
    host: { 'class': 'data-page-container' }
})
export class DataPage implements OnInit {
    public trips: Trip[] = [];

    constructor(
        private readonly _bottomSheet: MatBottomSheet,
        private readonly _settings: SettingsService,
        private readonly _snackBar: MatSnackBar,
        private readonly _tripManager: TripManagerService
    ) { }

    async deleteTrip(trip: Trip) {
        const data = { delete: false, message: `Do you really want to delete ${trip.name}?` }
        const ref = this._bottomSheet.open(DeleteFileSheet, { data });
        await firstValueFrom(ref.afterDismissed())
        if (!data.delete) { return }

        await this._delete_trip(trip);
    }

    async openTripDetailsSheet(trip: Trip) {
        this._bottomSheet.open(TripDetailSheet, { data: { trip: trip } });
    }

    async uploadTrip(trip: Trip) {
        try {
            await trip.upload();
        } catch (e) {
            if (e instanceof HttpErrorResponse) {
                switch (e.status) {
                    case 0:
                        this._snackBar.open("API not reachable", "OK", { duration: 2000 });
                        return;
                    case 409:
                        this._snackBar.open("File already uploaded", "OK", { duration: 2000 });
                        return;
                }
            }
            throw e;
        }

        if (!this._settings.deleteAfterUpload) {
            const data = { delete: false, message: `${trip.name} is now uploaded, would you like to delete it?` }

            const ref = this._bottomSheet.open(DeleteFileSheet, { data });
            await firstValueFrom(ref.afterDismissed())

            if (data.delete) {
                await this._delete_trip(trip);
            }
        }
    }
    
    /**
     * Downloads a trip by archiving it and creating a downloadable file.
     *
     * @param {Trip} trip - The trip to be downloaded
     */
    async downloadTrip(trip: Trip) {
        console.log("archiving...")
        const zipFile =  await trip.archive();
        console.log(zipFile)
        
        const filename = `${trip.name}.zip`
        let f = new File([zipFile], filename, { type: 'application/zip' })
        console.log(f)
        
        const url = URL.createObjectURL(f);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        a.click();
        URL.revokeObjectURL(url);
    }

    async ngOnInit() {
        this.trips = [];
        for await (const trip of this._tripManager.getTrips()) {
            this.trips.push(trip);
        }
    }

    private async _delete_trip(trip: Trip) {
        await trip.remove();
        const index = this.trips.findIndex(t => t.name === trip.name);
        if (index > -1) {
            this.trips.splice(index, 1);
        }
    }
}

@Component({
    selector: 'delete-file-sheet',
    templateUrl: './sheets/delete-file.sheet.html',
    styleUrls: ['./sheets/sheet.scss'],
    standalone: true,
    imports: [MatButtonModule],
})
export class DeleteFileSheet {
    constructor(
        private _bottomSheetRef: MatBottomSheetRef<DeleteFileSheet>,
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: { delete: boolean, message: string }
    ) { }

    deleteFile() {
        this.data.delete = true;
        this._bottomSheetRef.dismiss()
    }

    abort() {
        this._bottomSheetRef.dismiss()
    }
}


@Component({
    selector: 'file-detail-sheet',
    templateUrl: './sheets/file-details.sheet.html',
    styleUrls: ['./sheets/sheet.scss'],
    standalone: true,
    imports: [MatListModule, DatePipe, FileSizePipe],
})
export class TripDetailSheet implements OnInit {
    info?: TripInfo

    constructor(
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: { trip: Trip }
    ) { }

    async ngOnInit(): Promise<void> {
        this.info = await this.data.trip.info
    }
}