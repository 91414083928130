import { Component, OnInit, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet, RouterModule, Router } from '@angular/router';

// Material
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { CameraAccessDeniedError, LocationPermissionError, ModelLoadError, NoActiveModelError, NoCameraAvailableError } from './models/errors';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ActiveTrip, TripManagerService, TripState } from './services/trip-manager.service';
import { ObjectWhitelistCacheService } from './services/object-whitelist-cache.service';
import { SettingsService } from './services/settings.service';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';

// GoMap
import { InstallDialog } from './dialogs/install/install.dialog';
import { firstValueFrom } from 'rxjs';
import { ModelManagerService } from './services/model-manager.service';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [
        CommonModule,
        RouterOutlet,
        RouterModule,
        MatToolbarModule,
        MatIconModule,
        MatButtonModule,
        MatMenuModule,
        MatSnackBarModule,
        MatProgressSpinnerModule,
        MatDialogModule
    ],
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    TripState = TripState
    private _wakeLock: WakeLockSentinel | undefined;
    public trip = signal<ActiveTrip | undefined>(undefined);

    public sidenavLinks = [
        { title: "About", icon: "info", path: "/about" },
        { title: "Settings", icon: "settings", path: "/settings" }
    ]

    get isMainPage(): boolean {
        return this._router.url === '/'
    }

    constructor(
        private readonly _snackBar: MatSnackBar,
        private readonly _router: Router,
        private readonly _tripManager: TripManagerService,
        private readonly _objectWhitelistCache: ObjectWhitelistCacheService,
        private readonly _settings: SettingsService,
        private readonly _modelManager: ModelManagerService,
        private readonly _dialog: MatDialog
    ) { }

    async ngOnInit(): Promise<void> {
        const hasSettings = await this._settings.hasSettings();
        if (hasSettings) {
            const settings = await this._settings.fetchLocalSettings();
            this._settings.assignSettings(settings);
        } else {
            const ref = this._dialog.open(InstallDialog, { disableClose: true, width: "80%", maxWidth: "400px" });
            await firstValueFrom(ref.afterClosed());
        }

        if (this._settings.activeModel == null) {
            const ref = this._snackBar.open("No activated model available", "Activate")
            await firstValueFrom(ref.onAction())
            await this._router.navigate(["hub"])
        } else {
            await this._modelManager.activate(this._settings.activeModel)
        }

        try {
            await this._objectWhitelistCache.load();
            await this._objectWhitelistCache.refresh();
        } catch (e) {
            if (e instanceof HttpErrorResponse) {
                if (e.status >= 500) {
                    this._snackBar.open(
                        "The server seem to be out of service, please contact us.",
                        "OK",
                        { duration: 5000 }
                    );
                } else {
                    this._snackBar.open(
                        "Could not contact server, running in offline mode.",
                        "OK",
                        { duration: 5000 }
                    );
                }
            }
            throw e;
        }
    }

    get tripStarted(): boolean {
        return this._tripManager.activeTrip != null;
    }

    async startTrip() {
        try {
            if (navigator.wakeLock !== undefined) {
                this._wakeLock = await navigator.wakeLock.request("screen");
            }
            const trip = await this._tripManager.createTrip();
            this.trip.set(trip);
            await trip.start();
        } catch (e) {
            if (e instanceof LocationPermissionError) {
                this._snackBar.open(
                    "Permission to location tracking has been denied. Please use your browser to grant permission.",
                    "OK",
                    { duration: 5000 }
                )
                await this.stopTrip();
                return;
            } else if (e instanceof NoActiveModelError) {
                await this.stopTrip();
                const ref = this._snackBar.open("No activated model available", "Activate")
                await firstValueFrom(ref.onAction())
                await this._router.navigate(["hub"])
                return;
            } else if (e instanceof NoCameraAvailableError) {
                this._snackBar.open(
                    "Could not get access to a camera. Is the camera used by another application?",
                    "OK",
                    { duration: -1 }
                )
                await this.stopTrip();
                return;
            } else if (e instanceof CameraAccessDeniedError) {
                this._snackBar.open(
                    "Camera permission has been denied. Please give the app access to the camera.",
                    "OK",
                    { duration: -1 }
                )
                await this.stopTrip();
                return;
            } else if (e instanceof ModelLoadError) {
                this._snackBar.open(
                    "Unable to start recording. This is likely due to the phone not meeting min. hardware requirements.",
                    "OK",
                    { duration: -1 }
                )
                await this.stopTrip();
                return;
            }
            await this.stopTrip();
            throw e;
        }
    }

    async stopTrip() {
        try {
            await this.trip()?.stop();
        } catch (e) {
            if (e instanceof HttpErrorResponse) {
                switch (e.status) {
                    case 0:
                        this._snackBar.open("Could not upload file", "OK", { duration: 5000 })
                        break;
                    default:
                        throw e;
                }
            }
            throw e;
        } finally {
            this.trip.set(undefined);
            await this._wakeLock?.release();
        }
    }
}
