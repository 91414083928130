import { Detection } from "@models/detection.model";

export function getCurrentDateTimeFormatted(): string {
    const now = new Date();

    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Month is zero-based
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    return `${year}${month}${day}-${hours}${minutes}${seconds}`;
}

export async function crop(bitmap: ImageBitmap, detection: Detection, maxSize: number = 0): Promise<Blob> {
    let scale = 1;
    if (maxSize > 0) {
        scale = Math.min(maxSize / detection.width, maxSize / detection.height);
    }

    const canvas = new OffscreenCanvas(detection.width * scale, detection.height * scale);
    const ctx = canvas.getContext("2d")!;

    ctx.drawImage(
        bitmap,
        detection.x,
        detection.y,
        detection.width,
        detection.height,
        0,
        0,
        detection.width * scale,
        detection.height * scale
    );
    return await canvas.convertToBlob({ type: "image/jpeg", quality: 0.8 })
}

export async function toBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            switch (typeof reader.result) {
                case "string":
                    resolve(reader.result)
                    break;
                default:
                    throw new Error('Could not convert blob to base64 string');
            }
        };
        reader.readAsDataURL(blob);
    });
}

export async function asyncIterableToArray<T>(iterable: AsyncIterable<T>): Promise<T[]> {
    const result: T[] = [];
    for await (const item of iterable) {
        result.push(item);
    }
    return result;
}
