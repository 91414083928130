export class LocationPermissionError extends Error {
    constructor() {
        super("Permission to location service not granted")
    }
}

export class NoActiveModelError extends Error {
    constructor() {
        super("No active model available")
    }
}

export class InvalidStateError extends Error {
    constructor(msg: string) {
        super(msg)
    }
}

export class NoCameraAvailableError extends Error {
    constructor() {
        super("Camera not available")
    }
}

export class CameraAccessDeniedError extends Error {
    constructor() {
        super("Camera access has been denied.")
    }
}

export class ModelLoadError extends Error {
    constructor(error: Error) {
        super("Could not load model: " + error)
        this.stack += "\nCaused by: " + error.stack;
    }
}