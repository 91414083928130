import { Injectable } from '@angular/core';

// GoMap
import { SettingsService } from './settings.service';
import { PhotoSaveMode } from '@root/models/settings.model';
import { DetectionImage } from '@root/models/detection.model';
import { WritableFile } from '@root/workers/writableFile.worker';
import { Mutex } from 'async-mutex';
import { ObjectWhitelistCacheService } from './object-whitelist-cache.service';
import { IAsyncDisposable } from '@root/models/common';


class DetectionImageBuffer {
    private _buffer: DetectionImage[] = []
    constructor(private readonly _size: number) { }

    get length(): number { return this._buffer.length; }

    add(detectionImage: DetectionImage): void {
        if (this._buffer.length >= this._size) {
            this._buffer.shift();
        }
        this._buffer.push(detectionImage);
    }

    *[Symbol.iterator](): IterableIterator<DetectionImage> {
        for (const detectionImage of this._buffer) {
            yield detectionImage;
        }
    }
}

interface SaveStrategy {
    shouldSave(detectionImage: DetectionImage): boolean;
}

class PhotoSaverStrategy implements SaveStrategy {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    shouldSave(_detectionImage: DetectionImage) {
        return true;
    }
}

class ObjectPhotoSaverStrategy implements SaveStrategy {
    shouldSave(detectionImage: DetectionImage) {
        const detections = detectionImage.detections;
        if (detections.length === 0) { return false }
        return true;
    }
}

class WhitelistObjectPhotoSaverStrategy implements SaveStrategy {
    constructor(
        private readonly _whitelist: ObjectWhitelistCacheService
    ) {}

    shouldSave(detectionImage: DetectionImage) {
        const detections = detectionImage.detections;
        if (detections.length === 0) { return false }
        const intersection = this._whitelist.intersection(detections)
        if (intersection.length === 0) { return false }
        return true;
    }
}

export class PhotoSaver implements IAsyncDisposable {
    private _buffer!: DetectionImageBuffer;
    private _mutex = new Mutex();

    constructor(
        private readonly _saveStrategy: SaveStrategy,
        private readonly _settings: SettingsService,
        private _outputPath: string[]
    ) {
        this._resetBuffer();
    }

    private _disposed: boolean = false;
    get disposed(): boolean { return this._disposed; }

    async add(detectionImage: DetectionImage): Promise<void> {
        if (this.disposed) { throw new Error("PhotoSaver is disposed") }
        if (!this._settings.captureSettings.save) {
            return;
        }

        // All awaiting operatios are queued (FIFO)
        // Source: https://github.com/DirtyHairy/async-mutex/issues/26
        let tmpBuffer: DetectionImageBuffer | undefined;
        await this._mutex.runExclusive(() => {
            this._buffer.add(detectionImage);
            if (this._saveStrategy.shouldSave(detectionImage)) {
                tmpBuffer = this._buffer;
                this._resetBuffer();
            }
        });

        if (tmpBuffer != null) {
            await this._save(tmpBuffer);
        }
    }

    private async _save(buffer: DetectionImageBuffer): Promise<void> {
        for (const detectionImage of buffer) {
            const bitmap = detectionImage.image;
            const canvas = new OffscreenCanvas(bitmap.width, bitmap.height);
            const ctx = canvas.getContext('2d');

            if (!ctx) {
                console.warn("Image not saved as canvas context could not be created");
                continue;
            }

            ctx.drawImage(bitmap, 0, 0);
            const blob = await canvas.convertToBlob({ type: "image/jpeg", quality: 0.8 })

            const writableFile = WritableFile.construct();
            const file = await new writableFile(this._outputPath.concat(`${detectionImage.id}.jpeg`)) as unknown as WritableFile;
            await file.open(true, false);
            await file.write(blob);
            await file.close();
        }
    }

    async asyncDispose() {
        this._disposed = true;
        await this._mutex.waitForUnlock();
    }

    private _resetBuffer(): void {
        this._buffer = new DetectionImageBuffer(this._settings.captureSettings.history + 1);
    }
}

@Injectable({
    providedIn: 'root'
})
export class PhotoSaverFactoryService {
    constructor(
        private readonly _settings: SettingsService,
        private readonly _whitelist: ObjectWhitelistCacheService
    ) { }

    create(outputPath: string[]): PhotoSaver {
        let saveStrategy: SaveStrategy;
        switch (this._settings.captureSettings.saveMode) {
            case PhotoSaveMode.Photo:
                saveStrategy = new PhotoSaverStrategy();
                break;
            case PhotoSaveMode.ObjectPhoto:
                saveStrategy = new ObjectPhotoSaverStrategy();
                break;
            case PhotoSaveMode.WhitelistObjectPhoto: {
                console.debug("Using object whitelist", this._whitelist);
                saveStrategy = new WhitelistObjectPhotoSaverStrategy(this._whitelist)
                break;
            }
            default:
                throw new Error("Invalid save mode")
        }
        return new PhotoSaver(saveStrategy, this._settings, outputPath);
    }
}
