import { Component, signal } from '@angular/core';
import * as OPFSUtils from '@root/utilities/opfsUtils';
// Material
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';

@Component({
    selector: 'app-restore',
    standalone: true,
    imports: [MatDialogModule, MatButtonModule],
    templateUrl: './restore.dialog.html',
    styleUrl: './restore.dialog.scss'
})
export class RestoreDialog {
    title = signal("Warning: Restore");
    message = signal("Are you sure you want to restore to factory settings?");
    agree = signal(false);

    constructor(
        private readonly _dialogRef: MatDialogRef<RestoreDialog>,
        private readonly _router: Router
    ) { }

    async restore() {
        this.agree.set(true);
        const root = await OPFSUtils.getDirectoryHandle([]);
        await root.removeEntry("settings.json");

        this._router.navigate(
            ['/']).then(() => window.location.reload(),
            e => { 
                console.error(e);
                throw e;
            }
        );
        this._dialogRef.close();
    }
}
