import { Component, OnDestroy, Directive, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

// Material
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatBottomSheetModule, MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';

// GoMap
import { SettingsService } from '@services/settings.service';
import { ApiService } from '@root/services/api.service';
import { ImageSize, PhotoCaptureMode, PhotoSaveMode } from '@root/models/settings.model';
import { RestoreDialog } from '@root/dialogs/restore/restore.dialog';

@Component({
    selector: 'app-settings',
    standalone: true,
    imports: [
        CommonModule,
        MatCardModule,
        MatSlideToggleModule,
        FormsModule,
        MatIconModule,
        MatButtonModule,
        MatDialogModule,
        MatBottomSheetModule,
        MatSelectModule,
        MatSliderModule
    ],
    templateUrl: './settings.page.html',
    styleUrls: ['./settings.page.scss']
})
export class SettingsPage {
    PhotoCaptureMode = PhotoCaptureMode;
    PhotoSaveMode = PhotoSaveMode;

    sizes: ImageSize[] = [
        { width: 640, height: 480 },
        { width: 640, height: 360 },
        { width: 800, height: 600 },
        { width: 960, height: 720 },
        { width: 960, height: 540 },
        { width: 1024, height: 768 },
        { width: 1280, height: 960 },
        { width: 1280, height: 720 },
        { width: 1440, height: 1080 },
        { width: 1920, height: 1440 },
        { width: 1920, height: 1080 },
        { width: 3840, height: 2160 },
        { width: 4000, height: 3000 },
    ];

    constructor(
        public readonly settings: SettingsService,
        private readonly _bottomSheet: MatBottomSheet,
        private readonly _dialog: MatDialog
    ) { }

    aspectRatio(size: ImageSize): string {
        if (size.width / size.height - 4 / 3 <= 0.01) {
            return "4:3";
        } else if (size.width / size.height - 16 / 9 <= 0.01) {
            return "16:9";
        }

        return "";
    }

    sizeComparer(a: ImageSize, b: ImageSize): boolean {
        return a.width === b.width && a.height === b.height;
    }

    openUserSheet() {
        this._bottomSheet.open(UserSheet);
    }

    openMbrSizeDialog() {
        this._bottomSheet.open(MbrSizeSheet);
    }

    restore() {
        this._dialog.open(RestoreDialog, { disableClose: true, width: "80%", maxWidth: "400px" });
    }
}

@Directive({
    standalone: true,
})
class SettingsSheet implements OnDestroy {
    constructor(public readonly settings: SettingsService) { }

    async ngOnDestroy(): Promise<void> {
        await this.settings.save();
    }
}

@Component({
    standalone: true,
    imports: [CommonModule, MatSliderModule, FormsModule],
    templateUrl: './sheet-templates/mbr-size.sheet.html',
    styleUrls: ['./settings.sheet.scss']
})
class MbrSizeSheet extends SettingsSheet { }

@Component({
    standalone: true,
    imports: [CommonModule, MatButtonModule],
    templateUrl: './sheet-templates/user.sheet.html',
    styleUrls: ['./settings.sheet.scss']
})
class UserSheet extends SettingsSheet implements OnInit {
    userId: string = "";

    constructor(private readonly _api: ApiService, settings: SettingsService) {
        super(settings)
    }

    async ngOnInit(): Promise<void> {
        this.userId = await this.settings.getUserId(this._api);
    }

    async resetId(): Promise<void> {
        const user = await this._api.getUser();
        this.settings.userId = user.id;
        await this.settings.save();
        this.userId = user.id;
    }
}
