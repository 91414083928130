<div class="container">
    <mat-card>
        <mat-card-header>
            <mat-card-title>General</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div class="settings">
                <div class="setting-container">
                    <span class="setting-title">User</span>
                    <button mat-icon-button (click)="openUserSheet()">
                        <mat-icon>tune</mat-icon>
                    </button>
                </div>
            </div>
            <div class="settings">
                <button mat-raised-button color="warn" (click)="restore()">
                    Restore to Factory Settings
                </button>
            </div>
        </mat-card-content>
    </mat-card>

    <mat-card>
        <mat-card-header>
            <mat-card-title>Photo Capture</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div class="settings">
                <mat-form-field>
                    <mat-label>Capture Trigger Mode</mat-label>
                    <mat-select [(ngModel)]="settings.captureSettings.captureMode" (ngModelChange)="settings.save()">
                        <mat-option [value]="PhotoCaptureMode.TimeInterval">Time interval</mat-option>
                        <mat-option [value]="PhotoCaptureMode.PointBasedDistanceInterval">Distance interval (Point)</mat-option>
                        <mat-option [value]="PhotoCaptureMode.SpeedBasedDistanceInterval">Distance interval (Speed)</mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="slider-container">
                    <label>Interval</label>
                    @if(settings.captureSettings.captureMode === PhotoCaptureMode.TimeInterval){
                    <mat-slider min="0" max="10000" step="500" class="grow">
                        <input matSliderThumb
                               [(ngModel)]="settings.captureSettings.timeInterval"
                               (dragEnd)="settings.save()">
                    </mat-slider>
                    <span>
                        <ng-template [ngIf]="settings.captureSettings.timeInterval == 0"
                                     [ngIfElse]="second">Continuous</ng-template>
                        <ng-template #second>
                            <ng-template [ngIf]="settings.captureSettings.timeInterval <= 1000" [ngIfElse]="seconds">
                                {{settings.captureSettings.timeInterval / 1000}} second
                            </ng-template>
                        </ng-template>
                        <ng-template #seconds>
                            <ng-template [ngIf]="settings.captureSettings.timeInterval > 1000">
                                {{settings.captureSettings.timeInterval / 1000}} seconds
                            </ng-template>
                        </ng-template>
                    </span>

                    } @else {
                    <mat-slider min="1" max="100" step="1" class="grow">
                        <input matSliderThumb
                               [(ngModel)]="settings.captureSettings.distanceInterval"
                               (dragEnd)="settings.save()">
                    </mat-slider>
                    <span>{{settings.captureSettings.distanceInterval}} meter</span>
                    }
                </div>

                <mat-form-field>
                    <mat-label>Ideal Photo Size</mat-label>
                    <mat-select [(ngModel)]="settings.captureSettings.idealSize"
                                (ngModelChange)="settings.save()"
                                [compareWith]="sizeComparer">
                        @for (size of sizes; track size) {
                        <mat-option [value]="size">{{size.width}}x{{size.height}} ({{aspectRatio(size)}})</mat-option>
                        }
                    </mat-select>
                </mat-form-field>

                <div class="setting-container">
                    <span class="setting-title">Save Photo</span>
                    <mat-slide-toggle [(ngModel)]="settings.captureSettings.save"
                                      (ngModelChange)="settings.save()" />
                </div>

                <mat-form-field>
                    <mat-label>Photo Save Mode</mat-label>
                    <mat-select [(ngModel)]="settings.captureSettings.saveMode"
                                (ngModelChange)="settings.save()"
                                [disabled]="!settings.captureSettings.save">
                        <mat-option [value]="PhotoSaveMode.Photo">Each Photo</mat-option>
                        <mat-option [value]="PhotoSaveMode.ObjectPhoto">Each Object Photo</mat-option>
                        <mat-option [value]="PhotoSaveMode.WhitelistObjectPhoto">Each Whitelist Object
                            Photo</mat-option>
                    </mat-select>
                </mat-form-field>

                <div class="slider-container">
                    <label>History</label>
                    <mat-slider min="0" max="10" step="1" class="grow">
                        <input matSliderThumb
                               [(ngModel)]="settings.captureSettings.history"
                               (dragEnd)="settings.save()"
                               [disabled]="!settings.captureSettings.save || settings.captureSettings.saveMode===PhotoSaveMode.Photo">
                    </mat-slider>
                    <span>{{settings.captureSettings.history}} images</span>
                </div>
            </div>

        </mat-card-content>
    </mat-card>

    <mat-card>
        <mat-card-header>
            <mat-card-title>Data</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div class="settings">
                <div class="setting-container">
                    <span class="setting-title">Auto Upload</span>
                    <mat-slide-toggle [(ngModel)]="settings.autoUpload"
                                      (ngModelChange)="settings.save()"></mat-slide-toggle>
                </div>
                <div class="setting-container">
                    <span class="setting-title">Delete data file after upload</span>
                    <mat-slide-toggle [(ngModel)]="settings.deleteAfterUpload"
                                      (ngModelChange)="settings.save()"></mat-slide-toggle>
                </div>
                <div class="setting-container">
                    <span class="setting-title">Include MBR</span>
                    <mat-slide-toggle [(ngModel)]="settings.includeMBR"
                                      (ngModelChange)="settings.save()"></mat-slide-toggle>
                </div>
                <div class="setting-container">
                    <span class="setting-title">Max. MBR size</span>
                    <button mat-icon-button (click)="openMbrSizeDialog()" [disabled]="!settings.includeMBR">
                        <mat-icon>tune</mat-icon>
                    </button>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>