import { effect, FactoryProvider, Injectable, Injector } from '@angular/core';
import { SettingsService } from './settings.service';
import { PhotoCaptureMode } from '@root/models/settings.model';
import { PositionStream, PositionStreamFactoryService } from './position-stream-factory.service';
import { IAsyncDisposable } from '@root/models/common';
import * as GeoUtils from '@root/utilities/geoUtils';

// export interface TripSequencer extends AsyncIterator<number, void>, IAsyncDisposable {
//     [Symbol.asyncIterator](): AsyncIterator<number, void>;
// }


export abstract class TripSequencer implements AsyncIterator<number, void>, IAsyncDisposable {
    public abstract disposed: boolean;
    public abstract asyncDispose(): Promise<void>
    public abstract next(): Promise<IteratorResult<number, void>>
    [Symbol.asyncIterator](): AsyncIterator<number, void> {
        return this;
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Time based sequencer
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
class TimeSequence extends TripSequencer {
    private _sequenceId: number = 1;
    constructor(private readonly _interval: number) { super() }



    private _disposed: boolean = false;
    get disposed(): boolean {
        return this._disposed;
    }

    async next(): Promise<IteratorResult<number, void>> {
        if (this.disposed) {
            return { done: true, value: undefined };
        }
        if (this._sequenceId > 1) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            await new Promise((resolve, _) => setTimeout(resolve, this._interval));
        }
        return { done: false, value: this._sequenceId++ };
    }

    override async asyncDispose(): Promise<void> {
        // TODO: Clear timeout
        this._disposed = true;
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Distance based sequencer
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
class DistanceSequence extends TripSequencer {
    private _sequenceId: number = 1;
    private _lastTriggerLocation: GeolocationPosition | undefined;

    constructor(
        private readonly _interval: number,
        private readonly positionStream: PositionStream,
    ) { super() }

    private _disposed: boolean = false;
    get disposed(): boolean {
        return this._disposed;
    }

    async next(): Promise<IteratorResult<number, void>> {
        if (this.disposed) {
            return { done: true, value: undefined };
        }
        if (this._sequenceId === 1) {
            return { done: false, value: this._sequenceId++ };
        }
        // Wait for the distance to be great enough to return the next sequence
        for await (const position of this.positionStream) {
            if (this._lastTriggerLocation === undefined) {
                this._lastTriggerLocation = position;
            }
            const distance = GeoUtils.distance(
                this._lastTriggerLocation.coords.latitude,
                this._lastTriggerLocation.coords.longitude,
                position.coords.latitude,
                position.coords.longitude
            );
            if (this._interval <= distance) {
                this._lastTriggerLocation = position;
                break;
            }
        }

        if (this.disposed) {
            return { done: true, value: undefined };
        } else {
            return { done: false, value: this._sequenceId++ };
        }
    }

    override async asyncDispose(): Promise<void> {
        this._disposed = true;
        return this.positionStream.asyncDispose();
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Speed based sequencer
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
@Injectable()
class SpeedSequence extends TripSequencer {
    private _sequenceId: number = 1;
    private _currentPosition: GeolocationPosition | undefined;
    private _lastPosition: GeolocationPosition | undefined;
    private readonly _interval: number
    private readonly _speedThreshold: number
    private readonly _abortController = new AbortController();

    /**
     * Initializes a new instance of the SpeedSequence class.
     *
     * @param {number} _interval - The distance interval (in meters) at which to trigger the sequence.
     * @param {PositionStream} _positionStream - The stream of position data.
     * @param {number} _speedThreshold - The speed threshold in m/s.
     */
    constructor(
        injector: Injector,
        settings: SettingsService,
        private readonly _positionStream: PositionStream
    ) {
        super();
        this._interval = settings.captureSettings.distanceInterval
        this._speedThreshold = settings.captureSettings.speedThreshold

        effect(() => {
            let position = this._positionStream.location()
            if (position !== undefined) {
                this._lastPosition = this._currentPosition
                this._currentPosition = position
            }
        }, { injector: injector })
    }


    private _disposed: boolean = false;
    get disposed(): boolean {
        return this._disposed;
    }

    get speed(): number {
        const last = this._lastPosition
        const current = this._currentPosition

        if (last == null || current == null || last === current) {
            return 0;
        }
        if (current.coords.speed == null) {
            return GeoUtils.speedFromCoords(
                last.coords.latitude,
                last.coords.longitude,
                last.timestamp,
                current.coords.latitude,
                current.coords.longitude,
                current.timestamp
            );
        }

        return current.coords.speed;
    }

    override asyncDispose(): Promise<void> {
        this._abortController.abort("Disposing");
        this._disposed = true;
        return this._positionStream.asyncDispose();
    }

    async next(): Promise<IteratorResult<number, void>> {
        try {
            this._abortController.signal.throwIfAborted();
            if (this._sequenceId === 1) {
                return { done: false, value: this._sequenceId++ };
            }
            await this._idle();
            await new Promise((resolve, _) => setTimeout(resolve, (this._interval / this.speed) * 1000));
            return { done: false, value: this._sequenceId++ };
        } catch (error) {
            if (typeof error === "string" && error === "Disposing") {
                return { done: true, value: undefined };
            }
            throw error;
        }
    }

    private async _idle(): Promise<void> {
        while (this.speed < this._speedThreshold) {
            this._abortController.signal.throwIfAborted();
            await new Promise((resolve, _) => setTimeout(resolve, 1000));
        }
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Factory
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
@Injectable({
    providedIn: 'root'
})
export class SequenceFactoryService {

    constructor(
        private readonly _injector: Injector,
        private readonly _settings: SettingsService,
        private readonly _positionStreamFactory: PositionStreamFactoryService
    ) { }

    async create(): Promise<TripSequencer> {
        switch (this._settings.captureSettings.captureMode) {
            case PhotoCaptureMode.TimeInterval:
                return new TimeSequence(this._settings.captureSettings.timeInterval);
            case PhotoCaptureMode.PointBasedDistanceInterval: {
                const positionStream = await this._positionStreamFactory.create();
                return new DistanceSequence(this._settings.captureSettings.distanceInterval, positionStream);
            }
            case PhotoCaptureMode.SpeedBasedDistanceInterval: {
                const positionStream = await this._positionStreamFactory.create();
                return new SpeedSequence(this._injector, this._settings, positionStream);
            }
        }
    }
}
