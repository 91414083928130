@switch (tripManager.activeTrip()?.state()) {
    @case (TripState.Started) {
        <canvas appDetectionCanvas [image]="tripManager.activeTrip()?.detectionImage()" [fontSize]="16"></canvas>
    }
    @case (TripState.CreatingModel) {
        <h1>Setting Up Model...</h1>
    }
    @case (TripState.ActivatingCamera) {
        <h1>Starting Camera...</h1>
    }
    @case (TripState.Stopping) {
        <h1>Wrapping Up...</h1>
    }
    @default {
        <img class="img-placeholder" src="assets/landing.jpg">
    }

}