import { Injectable, Provider } from '@angular/core';
import { Writer, WriterFactory } from '@models/writer.model';
import { WritableFile } from '@root/workers/writableFile.worker';


export class CSVSerializer {
    private columns: string[] | undefined;

    constructor(
        public readonly separator: string = ",",
        public readonly includeHeader: boolean = true
    ) { }

    public serialize(obj: object): string {
        let result = "";
        if (this.columns == null) {
            this.columns = Object.keys(obj);
            if (this.includeHeader) {
                result += this.columns.join(this.separator) + "\n"
            }
        }
        result += this.columns
            .map(column => <any>obj[column as keyof typeof obj])
            .map(v => {
                if (typeof v == "string" || v instanceof String) {
                    return `"${v}"`;
                }
                return v
            })
            .join(this.separator) + "\n";
        return result;
    }
}

export class CSVWriter implements Writer {
    constructor(
        public readonly file: WritableFile,
        public readonly serializer: CSVSerializer
    ) { }

    public async write(obj: object): Promise<void> {
        if (obj == null) return;
        await this.file.write(this.serializer.serialize(obj));
    }

    public async writeAll(objs: object[]): Promise<void> {
        for (const obj of objs) {
            await this.write(obj);
        }
    }
}

@Injectable({
    providedIn: 'root'
})
export class CSVWriterFactory implements WriterFactory {

    constructor() { }

    create(writableFile: WritableFile): CSVWriter {
        const serializer = new CSVSerializer();
        return new CSVWriter(writableFile, serializer);
    }
}

export function provideCSVWriterFactory(): Provider {
    return { provide: WriterFactory, useClass: CSVWriterFactory };
}