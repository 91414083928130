import { Component, OnInit, ViewEncapsulation, OnDestroy, NgZone } from '@angular/core';


import { MapGeoJSONFeature, Map as MapLibreMap, Popup } from 'maplibre-gl'
import { first } from 'rxjs';

@Component({
    selector: 'app-map',
    standalone: true,
    imports: [],
    templateUrl: './map.page.html',
    styleUrl: './map.page.scss',
    encapsulation: ViewEncapsulation.None
})
export class MapPage implements OnInit, OnDestroy {
    mapInstance: MapLibreMap | undefined;

    constructor(private readonly zone: NgZone) { }

    async ngOnInit(): Promise<void> {
        // We need to wait for the onStable event in order to ensure routing has completed before rendering the map
        this.zone.onStable.pipe(first()).subscribe(async () => {
            this.mapInstance = new MapLibreMap({
                container: 'maplibre-map',
                center: [10.24965569208586, 55.68956808476325],
                zoom: 5.7,
                style: "https://tiles.aaudaisy.duckdns.org/styles/basic/style.json",
            });

            this.mapInstance.once("idle", () => {
                if (this.mapInstance == null) {
                    throw new Error("Map instance is null, but idle event fired");
                }
                this.mapInstance.addSprite("traffic-sign", "https://asset.aaudaisy.duckdns.org/sprites/traffic_signs/sprite");
                this.mapInstance.addSource("traffic-signs", { type: "vector", url: "https://tiles.aaudaisy.duckdns.org/data/traffic-signs.json" });
                this.mapInstance.addLayer({
                    id: "traffic-signs",
                    type: "symbol",
                    source: "traffic-signs",
                    "source-layer": "traffic-signs",
                    layout: {
                        "icon-image": ["concat", "traffic-sign:", ["get", "cls_name"]] ,
                        "icon-overlap": "always",
                    },
                })
                this._addPopup(this.mapInstance);
            });
        })
    }

    ngOnDestroy(): void {
        this.mapInstance?.remove();
    }

    private _generatePopupContent(feature: MapGeoJSONFeature) {
        return `
<table class="maplibre-popup-tbl">
<tr>
    <th>Class</th>
    <td>${feature.properties["cls_name"].toUpperCase()}</td>
</tr>
<tr>
    <th>Confidence</th>
    <td>${feature.properties["score"].toFixed(2)}</td>
</tr>
<tr>
    <th>Heading</th>
    <td>${feature.properties["heading"].toFixed()}</td>
</tr>
<tr>
    <th>Detections</th>
    <td>${feature.properties["detections"]}</td>
</tr>              
</table>
`
    }

    private _addPopup(map: MapLibreMap) {
        map.on("click", "traffic-signs", (e) => {
            if (e.features == null) { return }
            const geom = e.features[0].geometry as GeoJSON.Point;
            const coords = geom.coordinates.slice(0, 2) as [number, number]
            new Popup()
                .setLngLat(coords)
                .setOffset([0, -10])
                .setHTML(this._generatePopupContent(e.features[0]))
                .addTo(map);

        });

        map.on("mouseenter", "traffic-signs", () => {
            map.getCanvas().style.cursor = 'pointer';
        });

        map.on("mouseleave", "traffic-signs", () => {
            map.getCanvas().style.cursor = '';
        });

    }
}
