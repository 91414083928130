import { Injectable, computed, signal } from '@angular/core';

export class ProgressTracker {
    private _progress = signal(0);
    progress = computed(() => this._progress() / this.work * 100)
    completed = computed(() => this._progress() === this.work)

    constructor(
        public readonly work: number
    ) { }

    tick(): void {
        this._progress.update(p => p + 1);
    }

    complete(): void {
        this._progress.set(this.work);
    }
}

@Injectable({
    providedIn: 'root'
})
export class ProgressTrackerFactoryService {
    constructor() { }

    create(work: number): ProgressTracker {
        return new ProgressTracker(work);
    }
}
