import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

// RxJs
import { firstValueFrom } from 'rxjs';

// Tensorflow
import { ModelJSON } from '@tensorflow/tfjs-core/dist/io/types'

// GoMap
import { ModelInfo, ModelKey } from '@models/detection.model';
import { SettingsService } from './settings.service';
import { ModelId, User } from '@models/api.model';


@Injectable({
    providedIn: 'root'
})
export class ApiService {
    constructor(
        private readonly _httpClient: HttpClient,
        private readonly _settings: SettingsService
    ) { }

    async getUser(): Promise<User> {
        const url = this._settings.apiSettings.toString() + "/user/create";
        return await firstValueFrom(this._httpClient.get<User>(url))
    }

    async getModelIds(): Promise<ModelId[]> {
        const url = this._settings.apiSettings.toString() + `/model/ids`;
        return await firstValueFrom(this._httpClient.get<ModelId[]>(url));
    }

    async getModelInfo(id: string): Promise<ModelInfo> {
        const url = this._settings.apiSettings.toString() + `/model/id/${id}/info`;
        return await firstValueFrom(this._httpClient.get<ModelInfo>(url));
    }

    async getModelClasses(key: ModelKey): Promise<string[]> {
        const url = this._settings.apiSettings.toString() + `/model/id/${key.id}/version/${key.version}/classes`;
        return await firstValueFrom(this._httpClient.get<string[]>(url));
    
    }

    async getModel(key: ModelKey): Promise<ModelJSON> {
        const url = this._settings.apiSettings.toString()
            + `/model/id/${key.id}/version/${key.version}/size/${key.size}`;
        return await firstValueFrom(this._httpClient.get<ModelJSON>(url));
    }

    async getModelShard(key: ModelKey, shard: string): Promise<Blob> {
        const url = this._settings.apiSettings.toString()
            + `/model/id/${key.id}/version/${key.version}/size/${key.size}/shard/${shard}`;
        return await firstValueFrom(this._httpClient.get<Blob>(url, { responseType: "blob" as "json" }));
    }

    async getObjectWhitelist(): Promise<Set<string>> {
        const url = this._settings.apiSettings.toString() + "/object/whitelist";
        const whitelist = await firstValueFrom(this._httpClient.get<string[]>(url));
        return new Set(whitelist);
    }

    async upload(data: Blob, directory: string) {
        let userId = await this._settings.getUserId(this);
        const url = this._settings.apiSettings.toString() + `/upload/user/${userId}/${directory}`;
        const formData: FormData = new FormData();
        formData.append("file", data);
        return await firstValueFrom(this._httpClient.put(url, formData, { responseType: "json" }));
    }
}
