import { Component, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';

// Material
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';

// GoMap
import { SettingsService } from '@root/services/settings.service';
import { ModelManagerService } from '@root/services/model-manager.service';

@Component({
    selector: 'app-install',
    standalone: true,
    imports: [
        MatDialogModule,
        MatButtonModule,
        MatProgressBarModule,
        MatProgressSpinnerModule
    ],
    templateUrl: './install.dialog.html',
    styleUrl: './install.dialog.scss'
})
export class InstallDialog {
    initializing = signal(false);
    agree = signal(false);
    title = signal("Initialize");
    message = signal("We need to initialize the app, do you wish to proceed?");

    constructor(
        private readonly _dialogRef: MatDialogRef<InstallDialog>,
        private readonly _settings: SettingsService,
        private readonly _modelManager: ModelManagerService
    ) { }

    async init() {
        this.agree.set(true);
        this.initializing.set(true);
        this.title.set("Initializing...");

        await this._fetchSettings();
        await this._installModel();
        
        this.initializing.set(false);

        this.message.set("Initialization complete");
        await new Promise(r => setTimeout(r, 2000));
        this._dialogRef.close();
    }

    private async _fetchSettings() {
        this.message.set("Fetching settings");
        const settings = await this._settings.fetchRemoteSettings();
        this._settings.assignSettings(settings);
        await this._settings.save();
    }

    private async _installModel() {
        if (this._settings.activeModel == null) {
            this.message.set("Default model not set in remote settings. Please contact system administrators.");
            throw new Error("No active model available")
        }
        this.message.set("Installing model...");
        await this._modelManager.install(this._settings.activeModel)
    }
}
