import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson';

export interface Detection {
    x: number;
    y: number;
    width: number;
    height: number;
    score: number;
    classifier: string;
    imgWidth: number;
    imgHeight: number;
}

export interface GeoTemporalDetection extends Detection {
    timestamp: number;
    latitude: number;
    longitude: number;
    accuracy: number; // in meter
    altitude: number | null;
    altitudeAccuracy: number | null;
    heading: number | null;
    speed: number | null; // meter per second
}

export interface SequentialGeoTemporalDetection extends GeoTemporalDetection {
    sequenceId: number;
}

export interface DetectionImage {
    id: number;
    image: ImageBitmap;
    detections: Detection[];
}

@jsonObject
export class ModelSize {
    @jsonMember
    width: number;
    @jsonMember
    height: number;

    constructor(width: number, height: number) {
        this.width = width;
        this.height = height;
    }

    equals(other: ModelSize): boolean {
        return this.width === other.width && this.height === other.height;
    }

    toString(): string {
        return `${this.width}x${this.height}`;
    }

    static fromString(value: string): ModelSize {
        const [width, height] = value.split('x')
            .map(v => parseInt(v));
        return new ModelSize(width, height);
    }
}

@jsonObject
export class ModelVersion {
    @jsonMember name: string;
    @jsonArrayMember(ModelSize) sizes: ModelSize[];
    @jsonArrayMember(String) classes: string[];

    constructor(name: string, sizes: ModelSize[], classes: string[]) {
        this.name = name;
        this.sizes = sizes;
        this.classes = classes;
    }

    equals(other: ModelVersion): boolean {
        return this.name === other.name
            && this.sizes.sort().join(",") === other.sizes.sort().join(",")
            && this.classes.join(',') === other.classes.join(',');
    }
}

@jsonObject
export class ModelKey {
    @jsonMember id: string;
    @jsonMember version: string;
    @jsonMember size: string;

    constructor(id: string, version: string, size: string) {
        this.id = id;
        this.version = version;
        this.size = size;
    }

    equals(other: ModelKey): boolean {
        return this.id === other.id
            && this.version === other.version
            && this.size === other.size;
    }

    toString(): string {
        return `${this.id}/${this.version}/${this.size}`;
    }
}

export interface Flavor {
    size: string
    versions: string[]
}

export interface ModelInfo {
    name: string
    description: string
    publisher: string[]
    license: string
    type: string
    flavors: Flavor[]
}

export interface DetectionModel {
    id: string;
    info: ModelInfo;
    versions: ModelVersion[];
}

export interface ObjectDetectionModel {
    readonly width: number;
    readonly height: number;
    readonly key: ModelKey;
    warmup(): Promise<void>;
    detect(bitmap: ImageBitmap): AsyncGenerator<Detection>;
    dispose(): void;
}

export abstract class ObjectDetectionModelFactory {
    abstract create(key: ModelKey): Promise<ObjectDetectionModel>
}
