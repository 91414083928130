@if(info; as info) {
    <div class="content-header">
        <p>Publisher: {{info.publisher.join(", ")}}</p>
        <p>License: {{info.license}}</p>
    </div>
    <p class="description">{{info.description}}</p>

    <mat-tab-group [(selectedIndex)]="tabIndex" (selectedTabChange)="onTabChange()">
        @for (flavor of info.flavors | modelFlavorSort; track flavor.size) {
            <mat-tab [label]="flavor.size">
                <div class="tab-content">
                    <mat-form-field>
                        <mat-label>Version</mat-label>
                        <mat-select [(ngModel)]="selectedVersion">
                            @for (version of sortVersionStrings(flavor.versions); track version) {
                                <mat-option [value]="version">
                                    {{version}}
                                </mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                </div>
            </mat-tab>
        }
    </mat-tab-group>

    <mat-action-row>
        <button mat-button [disabled]="!selectedVersion" (click)="reset()">Reset</button>
        @if(isInstalled){
            @if(isActivated){
                <button mat-button [disabled]="!selectedVersion"
                (click)="deactivate()">Deactivate</button>
            } @else {
                <button mat-button [disabled]="!selectedVersion"
                (click)="activate()">Activate</button>
            }
            <button mat-button color="warn" [disabled]="!selectedVersion" (click)="uninstall()">
                @if(uninstalling) {
                    Uninstalling...
                } @else {
                    Uninstall
                }
            </button>
        } @else if(selectedVersion) {
            <button mat-button [disabled]="!selectedVersion || installing" (click)="install()">
                @if(installing) {
                    Installing...
                } @else {
                    Install
                }
            </button>
        }
        
    </mat-action-row>
}
