import { Routes } from '@angular/router';
import { DataPage } from '@pages/data/data.page';
import { MainPage } from '@pages/main/main.page';
import { SettingsPage } from '@pages/settings/settings.page';
import { ModelHubPage } from './pages/hub/model-hub.page';
import { AboutPage } from './pages/about/about.page';
import { MapPage } from './pages/map/map.page';

export const routes: Routes = [
    { path: "", component: MainPage },
    { path: "data", component: DataPage },
    { path: "settings", component: SettingsPage },
    { path: "hub", component: ModelHubPage },
    { path: "about", component: AboutPage },
    { path: "map", component: MapPage }
];
